import { Maybe } from 'graphql/jsutils/Maybe'
import * as R from 'ramda'
import { useCallback } from 'react'

import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { UseCartContext } from '../../../cart'
import { useCartRemoveProduct } from '../../../cart/hooks/useCartRemoveProduct'
import { EScriptParamsLoader, EScriptUpdateCartAction } from '../../../escript/types'
import { isEmptyCartAction } from '../../../escript/utils'
import { setDispensaryCartState } from '../../events/cart'

export type UseEmptyCart = [emptyCart: () => Promise<void>]

export interface UseEmptyCartOptions {
  cartContext: UseCartContext
  eScriptParamsLoader: EScriptParamsLoader
}

const shouldEmptyCart = (updateCartActions: Maybe<EScriptUpdateCartAction[]>) =>
  updateCartActions && updateCartActions.some(isEmptyCartAction)

export const useEmptyCart = ({
  cartContext,
  eScriptParamsLoader,
}: UseEmptyCartOptions): UseEmptyCart => {
  const { loadCart } = cartContext
  const { loadParams } = eScriptParamsLoader

  const [removeProductFromCart, { error }] = useCartRemoveProduct()

  useLogAndCaptureError(error)

  const emptyCart = useCallback(async (): Promise<void> => {
    const [{ updateCartActions }, cart] = await Promise.all([loadParams(), loadCart()])

    if (!shouldEmptyCart(updateCartActions)) {
      return
    }
    if (!cart || !cart.items || cart.items.length === 0) {
      return
    }
    setDispensaryCartState({ type: 'cart_empty_start' })
    return cart.items
      .reduce(async (acc, item) => {
        await acc
        const { id } = item || {}
        await removeProductFromCart({
          variables: {
            itemId: Number(id),
          },
        })
      }, Promise.resolve())
      .then(R.tap(() => setDispensaryCartState({ type: 'cart_empty_complete' })))
  }, [loadCart, loadParams, removeProductFromCart])

  return [emptyCart]
}
