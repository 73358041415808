import type { IdToken } from '@auth0/auth0-react'
import { useCallback, useState } from 'react'

import useUserCategory from '../../../hooks/useUserCategory'
import { userCategories, userClaimIds } from '../../../utils/userClaims'
import { useAuthAsync } from '../../auth/hooks/useAuthAsync'

export interface UseUserDispensaryClaims {
  isValidUser: () => Promise<boolean>
  getClaims: () => Promise<UserDispensaryClaims | undefined>
  refreshClaims: () => Promise<UserDispensaryClaims | undefined>
  claims: UserDispensaryClaims | undefined
}

export interface UserDispensaryClaims {
  practitionerCode?: string
  practitionerCodeRecent?: string
}

export const useUserDispensaryClaims = (): UseUserDispensaryClaims => {
  const { loadAuthentication, getAccessTokenSilently, getIdTokenClaims } = useAuthAsync()
  const { parseUserCategory } = useUserCategory()
  const [claims, setClaims] = useState<UserDispensaryClaims | undefined>(undefined)

  const parseClaims = useCallback((idTokenClaims?: IdToken): UserDispensaryClaims | undefined => {
    if (!idTokenClaims) {
      return undefined
    }

    const {
      [userClaimIds.dispensaryPractitionerCode]: practitionerCode,
      [userClaimIds.dispensaryPractitionerCodeRecent]: practitionerCodeRecent,
    } = idTokenClaims

    if (!practitionerCode && !practitionerCodeRecent) {
      return undefined
    }

    return {
      ...(practitionerCode && { practitionerCode }),
      ...(practitionerCodeRecent && { practitionerCodeRecent }),
    }
  }, [])

  const isValidUser = useCallback(async (): Promise<boolean> => {
    const { isAuthenticated, user } = await loadAuthentication()
    if (!isAuthenticated || !user) {
      return false
    }
    const userCategory = parseUserCategory(user)
    return userCategory === userCategories.patient
  }, [loadAuthentication, parseUserCategory])

  const updateClaims = useCallback(
    async ({ refresh }: { refresh?: boolean } = {}): Promise<UserDispensaryClaims | undefined> => {
      if (refresh) {
        await getAccessTokenSilently({ ignoreCache: true })
      }
      const idTokenClaims = await getIdTokenClaims()
      const parsedClaims = parseClaims(idTokenClaims)
      setClaims(parsedClaims)
      return parsedClaims
    },
    [getAccessTokenSilently, getIdTokenClaims, parseClaims],
  )

  const getClaims = useCallback(async (): Promise<UserDispensaryClaims | undefined> => {
    return updateClaims({ refresh: false })
  }, [updateClaims])

  const refreshClaims = useCallback(async (): Promise<UserDispensaryClaims | undefined> => {
    return updateClaims({ refresh: true })
  }, [updateClaims])

  return {
    isValidUser,
    getClaims,
    refreshClaims,
    claims,
  }
}
