import * as _ from 'lodash'
import * as R from 'ramda'

import {
  AddSimpleAndVirtualProductsToCartMutation,
  AddSimpleProductsToCartMutation,
  CartFragment,
  SimpleProductCartItemInput,
  VirtualProductCartItemInput,
} from '../../graphql/magento'
import { isNotNull } from '../../utils/collectionTools'
import { appendToList } from '../../utils/ramda'
import { Maybe } from '../../utils/types'
import { CartItem, CartItemVirtualProduct } from '../cart/state/types'
import { AnalyticsEventAddToCart, AnalyticsItem, setAnalyticsEvent } from './state'

export type CartItemForAnalytics = CartItem & {
  product: { sku: NonNullable<CartItem['product']['sku']> }
  prices: {
    price: {
      currency: NonNullable<NonNullable<CartItem['prices']>['price']['currency']>
      value: NonNullable<NonNullable<CartItem['prices']>['price']['value']>
    }
  }
}

export function isCartItemForAnalytics(value: Maybe<CartItem>): value is CartItemForAnalytics {
  return (
    !!value &&
    !!value.product.sku &&
    !!value.prices?.price?.currency &&
    typeof value.prices.price.value === 'number'
  )
}

const analyticsItemReducer = (acc: AnalyticsItem[], cartItem: Maybe<CartItem>) =>
  isCartItemForAnalytics(cartItem)
    ? R.append(
        {
          item_id: cartItem.product.sku,
          item_name: cartItem.product.name ?? undefined,
          currency: cartItem.prices.price.currency,
          price: cartItem.prices.price.value,
          quantity: cartItem.quantity,
        },
        acc,
      )
    : acc

export const analyticsItemsFromCartItems = (cartItems: Maybe<CartItem>[]): AnalyticsItem[] =>
  R.reduce(analyticsItemReducer, [], cartItems)

export interface AddToCartInputItem {
  quantity: number
  sku: string
}

type AnalyticsItemNarrowed = AnalyticsItem &
  Required<Pick<AnalyticsItem, 'currency' | 'price' | 'quantity'>>

const isMatchingCartItem =
  (sku: string) =>
  (cartItem: CartItem): cartItem is CartItemForAnalytics =>
    isCartItemForAnalytics(cartItem) && cartItem.product.sku === sku

const isCustomizableOptionsEqual = (
  inputOptions: VirtualProductCartItemInput['customizable_options'],
  cartItemOptions: CartItemVirtualProduct['customizable_options'],
): boolean =>
  inputOptions == null
    ? cartItemOptions == null
    : cartItemOptions == null
      ? false
      : inputOptions.reduce((acc: boolean, inputOption, index) => {
          const cartItemOption = cartItemOptions[index]
          return (
            acc &&
            inputOption != null &&
            cartItemOption != null &&
            inputOption.id === cartItemOption.id &&
            // NOTE: this only supports string options
            cartItemOption.values[0]?.value === inputOption.value_string
          )
        }, true)

const isMatchingVirtualCartItem = (
  sku: string,
  options: VirtualProductCartItemInput['customizable_options'],
) => {
  const isMatchingCartItemForSku = isMatchingCartItem(sku)
  return (
    cartItem: CartItem,
  ): cartItem is Extract<CartItemForAnalytics, { __typename?: 'VirtualCartItem' }> =>
    isMatchingCartItemForSku(cartItem) &&
    cartItem.__typename === 'VirtualCartItem' &&
    isCustomizableOptionsEqual(options, cartItem.customizable_options)
}

const buildEventItem =
  ({ affiliation, cartItems }: { affiliation?: string; cartItems: CartItem[] }) =>
  ({ sku, quantity }: { sku: string; quantity: number }): AnalyticsItemNarrowed | undefined =>
    R.pipe(
      R.find(isMatchingCartItem(sku)),
      R.when(
        isNotNull,
        (cartItem): AnalyticsItemNarrowed => ({
          item_id: cartItem.product.sku,
          item_name: cartItem.product.name ?? undefined,
          affiliation,
          currency: cartItem.prices.price.currency,
          price: cartItem.prices.price.value,
          quantity,
        }),
      ),
    )(cartItems)

const buildEventItemForVirtual =
  ({ affiliation, cartItems }: { affiliation?: string; cartItems: CartItem[] }) =>
  ({
    data: { sku, quantity },
    customizable_options: inputOptions,
  }: VirtualProductCartItemInput): AnalyticsItemNarrowed | undefined =>
    R.pipe(
      R.find(isMatchingVirtualCartItem(sku, inputOptions)),
      R.when(
        isNotNull,
        (cartItem): AnalyticsItemNarrowed => ({
          item_id: cartItem.product.sku,
          item_name: cartItem.product.name ?? undefined,
          affiliation,
          currency: cartItem.prices.price.currency,
          price: cartItem.prices.price.value,
          quantity,
        }),
      ),
    )(cartItems)

const sumEventItemAmounts = R.reduce(
  (acc: number, item: AnalyticsItemNarrowed) => R.add(acc, R.multiply(item.price, item.quantity)),
  0,
)

export const publishAddToCartEventForItems =
  ({
    affiliation,
    items: inputItems,
    appContext,
  }: {
    affiliation?: string
    items: AddToCartInputItem[]
    appContext?: AnalyticsEventAddToCart['app_context']
  }) =>
  (data: Maybe<AddSimpleProductsToCartMutation>): void => {
    const cartItems = data?.addSimpleProductsToCart?.cart?.items?.filter(isNotNull)
    if (!cartItems) return

    const eventItems = R.reduce(
      (acc: AnalyticsItemNarrowed[], inputItem: AddToCartInputItem) =>
        R.pipe(
          buildEventItem({ affiliation, cartItems }),
          R.ifElse(isNotNull, appendToList(acc), () => acc),
        )(inputItem),
      [],
      inputItems,
    )
    const [firstEventItem] = eventItems
    if (!firstEventItem) return

    setAnalyticsEvent({
      event: 'add_to_cart',
      currency: firstEventItem.currency,
      value: _.round(sumEventItemAmounts(eventItems), 2),
      items: eventItems,
      app_context: appContext,
    })
  }

export const publishAddToCartEventForItemInputs =
  ({
    affiliation,
    simpleCartItems,
    virtualCartItems,
    appContext,
  }: {
    affiliation?: string
    simpleCartItems: SimpleProductCartItemInput[]
    virtualCartItems?: VirtualProductCartItemInput[]
    appContext?: AnalyticsEventAddToCart['app_context']
  }) =>
  (data: Maybe<AddSimpleAndVirtualProductsToCartMutation>): void => {
    // cart is returned from last mutation in query (addVirtualProductsToCart)
    const cartItems = data?.addVirtualProductsToCart?.cart?.items?.filter(isNotNull)
    if (!cartItems) return

    const eventItemsFromSimple = R.reduce(
      (acc: AnalyticsItemNarrowed[], { data: inputItem }: SimpleProductCartItemInput) =>
        R.pipe(
          buildEventItem({ affiliation, cartItems }),
          R.ifElse(isNotNull, appendToList(acc), () => acc),
        )(inputItem),
      [],
      simpleCartItems,
    )
    const [firstEventItem] = eventItemsFromSimple
    if (!firstEventItem) return

    const eventItemsFromVirtual = virtualCartItems
      ? R.reduce(
          (acc: AnalyticsItemNarrowed[], inputItem: VirtualProductCartItemInput) =>
            R.pipe(
              buildEventItemForVirtual({ affiliation, cartItems }),
              R.ifElse(isNotNull, appendToList(acc), () => acc),
            )(inputItem),
          [],
          virtualCartItems,
        )
      : []

    const eventItems = R.concat(eventItemsFromSimple, eventItemsFromVirtual)

    setAnalyticsEvent({
      event: 'add_to_cart',
      currency: firstEventItem.currency,
      value: _.round(sumEventItemAmounts(eventItems), 2),
      items: eventItems,
      app_context: appContext,
    })
  }

export const publishViewCartEvent = (cart: CartFragment): void => {
  const currency = cart.prices?.grand_total?.currency
  const totalValue = cart.prices?.grand_total?.value
  if (currency == null || totalValue == null) {
    return
  }
  setAnalyticsEvent({
    event: 'view_cart',
    currency,
    value: totalValue,
    items: analyticsItemsFromCartItems(cart.items ?? []),
  })
}

export const publishBeginCheckoutEvent = (cart: CartFragment): void => {
  const currency = cart.prices?.grand_total?.currency
  const totalValue = cart.prices?.grand_total?.value
  if (currency == null || totalValue == null) {
    return
  }
  setAnalyticsEvent({
    event: 'begin_checkout',
    currency,
    value: totalValue,
    coupon: cart.applied_coupons?.[0]?.code,
    items: analyticsItemsFromCartItems(cart.items ?? []),
  })
}
