import React, { useEffect, useMemo, useState } from 'react'

import useLogAndCaptureError from '../hooks/useLogAndCaptureError'
import useSiteMetadata from '../hooks/useSiteMetadata'
import { isMagentoScopeNotGrantedError } from '../lib/auth/errors'
import { CartContext, useCartGet } from '../lib/cart'
import {
  autoRemoveErrantCartItems$,
  cartItemCheckSetFromFeatureFlags,
  latestCartItemErrorsForRemoval$,
} from '../lib/cart/state/cart-items/cart-item-remove'
import {
  autoUpdateErrantCartItems$,
  cartItemsUpdating$,
  latestCartItemErrorsForUpdate$,
} from '../lib/cart/state/cart-items/cart-item-update'

const CartContextProvider: React.FC = ({ children }) => {
  const { featureFlags } = useSiteMetadata() ?? {}
  const [allowGuestCart, setAllowGuestCart] = useState(featureFlags?.anonymousCart ?? false)
  const { cart, error, loading, refetch } = useCartGet({ allowGuestCart })

  // no need to log and capture error if scope not granted
  useLogAndCaptureError(!isMagentoScopeNotGrantedError(error) ? error : undefined)

  useEffect(() => {
    const checkSet = cartItemCheckSetFromFeatureFlags(featureFlags)
    const subscriptions = [
      latestCartItemErrorsForUpdate$.subscribe(),
      autoUpdateErrantCartItems$().subscribe(),
      cartItemsUpdating$.subscribe(),
    ]
    if (checkSet) {
      subscriptions.push(latestCartItemErrorsForRemoval$(checkSet).subscribe())
      subscriptions.push(autoRemoveErrantCartItems$(checkSet).subscribe())
    }
    return () => subscriptions.forEach((subscription) => subscription.unsubscribe())
  }, [featureFlags])

  const cartContext = useMemo(
    () => ({ cart, error, loading, setAllowGuestCart, refetch }),
    [cart, error, loading, setAllowGuestCart, refetch],
  )

  return <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
}

export default CartContextProvider
