import { useEffect } from 'react'

import { useCartContext } from './useCartContext'

export interface UseCartContextOptions {
  allowGuestCart: boolean
}

export const useCartAllowGuest = ({ allowGuestCart }: UseCartContextOptions): void => {
  const { setAllowGuestCart } = useCartContext()

  useEffect(() => {
    setAllowGuestCart(allowGuestCart)
  }, [allowGuestCart, setAllowGuestCart])
}
