import { createContext } from 'react'

import type {
  ClickWrapContextValue,
  ClickWrapModalContextValue,
  UserDataProviderContextValue,
} from './types'

export const ClickWrapContext = createContext<ClickWrapContextValue>([
  {
    accepted: false,
    acceptPending: false,
  },
  () => {
    console.warn('ClickWrapContext not initialized')
  },
])

export const ClickWrapModalContext = createContext<ClickWrapModalContextValue>([
  {
    isOpen: false,
  },
  () => {
    console.warn('ClickWrapModalContext not initialized')
  },
])

export const UserDataProviderContext = createContext<UserDataProviderContextValue>({
  error: null,
  isActive: false,
  isFormValid: false,
  isSubmitting: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  editData() {},
  FormComponent: null,
})
