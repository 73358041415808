import React from 'react'
import styled from 'styled-components'
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'

const StyledModalBackground = styled(BaseModalBackground)({
  // use percentages instead of vh/vw to constrain modal background inside of scrollbars
  // - note this only works when modal background is a direct child of `body` or some other
  //   element with full width
  width: '100%',
  // constrain minimum width to reasonable value
  minWidth: '320px',
  height: '100%',
  zIndex: 10_000,
})

const StyledModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <ModalProvider backgroundComponent={StyledModalBackground}>{children}</ModalProvider>
}

export default StyledModalProvider
