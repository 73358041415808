import { bind } from '@react-rxjs/core'
import { filter, switchMap } from 'rxjs'

import {
  dispensaryBySlugQuery,
  homePageBySlugQuery,
  logoBySlugQuery,
  siteSettingsQuery,
} from '../../groq/sanity-dispensaries/dispensary'
import { isNotNull } from '../../utils/collectionTools'
import { dispensariesClientCdn, fetchQuery } from './client'
import { latestDispensarySlug$ } from './state'

export const [, sanityDispensaryCdn$] = bind(
  latestDispensarySlug$.pipe(
    filter(isNotNull),
    switchMap((slug) =>
      fetchQuery(dispensaryBySlugQuery, {
        slug,
      })(dispensariesClientCdn),
    ),
  ),
)

export const [useDispensaryLogoCdn, dispensaryLogoCdn$] = bind(
  latestDispensarySlug$.pipe(
    filter(isNotNull),
    switchMap((slug) =>
      fetchQuery(logoBySlugQuery, {
        slug,
      })(dispensariesClientCdn),
    ),
  ),
)

export const [, dispensaryHomePageCdn$] = bind(
  latestDispensarySlug$.pipe(
    filter(isNotNull),
    switchMap((slug) =>
      fetchQuery(homePageBySlugQuery, {
        slug,
      })(dispensariesClientCdn),
    ),
  ),
)

export const [useDispensaryBySlugCdn, latestDispensaryBySlugCdn$] = bind(
  latestDispensarySlug$.pipe(
    filter(isNotNull),
    switchMap((slug) =>
      fetchQuery(dispensaryBySlugQuery, {
        slug,
      })(dispensariesClientCdn),
    ),
  ),
)

export const [, dispensariesSiteSettingsCdn$] = bind(
  fetchQuery(siteSettingsQuery, undefined)(dispensariesClientCdn),
)
