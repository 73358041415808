import { MutationFunctionOptions, MutationResult, useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
import { useCallback } from 'react'

import { checkQueryResponse } from '../../../utils/graphqlTools'
import { useCartContext } from './useCartContext'

export type UseCartMutation<TMutation, TVariables> = [
  doCartMutation: (
    options: CartMutationOptions<TMutation, TVariables>,
  ) => Promise<TMutation | undefined | null>,
  result: MutationResult<TMutation>,
]

export interface UseCartMutationOptions<TVariables> {
  isValidVariables?: (
    variables?: CartMutationVariables<TVariables>,
  ) => variables is CartMutationVariables<TVariables>
  loginRequired?: boolean
}

export type CartMutationOptions<TMutation, TVariables> = MutationFunctionOptions<
  TMutation,
  CartMutationVariables<TVariables>
>

export type CartMutationVariables<TVariables> = Omit<TVariables, 'cartId'>

export const useCartMutation = <TMutation, TVariables>(
  document: DocumentNode<TMutation, TVariables>,
  { isValidVariables, loginRequired }: UseCartMutationOptions<TVariables> = {},
): UseCartMutation<TMutation, TVariables> => {
  const { getAccessTokenSilently } = useAuth0()
  const { loadCart } = useCartContext()
  const [cartMutation, result] = useMutation(document)

  const getToken = useCallback(async (): Promise<string | undefined> => {
    try {
      return await getAccessTokenSilently()
    } catch (error) {
      if (loginRequired) {
        throw error
      }
      return undefined
    }
  }, [getAccessTokenSilently, loginRequired])

  const doCartMutation = useCallback(
    async ({
      variables,
      ...options
    }: CartMutationOptions<TMutation, TVariables>): Promise<TMutation | undefined | null> => {
      const [token, cart] = await Promise.all([getToken(), loadCart()])
      if (!cart) {
        throw new Error(`Unable to load cart for mutation`)
      }
      if (isValidVariables && !isValidVariables(variables)) {
        throw new Error(
          `Invalid variables provided for cart mutation: ${JSON.stringify(variables)}`,
        )
      }
      const { id: cartId } = cart
      // Although the added `cartId` variable will be returned, there's no need to indicate
      // it's addition to the return type. We cast the variables type to the generic parameter
      // instead to maintain expected return type.
      return cartMutation({
        variables: { cartId, ...variables } as unknown as TVariables,
        context: { token },
        ...options,
      }).then(checkQueryResponse)
    },
    [getToken, loadCart, isValidVariables, cartMutation],
  )

  return [doCartMutation, result]
}
