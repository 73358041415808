import React, { useContext, useEffect, useState } from 'react'

import { latestDispensary$, setDispensary } from '../lib/dispensaries/state'
import { utilityMenuState$ } from '../lib/header/state'
import { LocationContext } from '../lib/pages/context'
import { publishProvisioningNeededEvent$ } from '../lib/provisioning/state'
import { dispensaryLogoCdn$ } from '../lib/sanity-dispensaries/query'
import { latestDispensarySlug$ } from '../lib/sanity-dispensaries/state'

interface GlobalSubscriptionsState {
  dispensary: boolean
}

const initialState: GlobalSubscriptionsState = {
  dispensary: false,
}

/**
 * Component to hold global RxJS subscriptions. Intended for use in root element wrapper.
 */
export const GlobalSubscriptions: React.FC = () => {
  // use LocationContext directly - useLocation throws an error if context value is null
  const { location } = useContext(LocationContext)
  const [state, setState] = useState<GlobalSubscriptionsState>(initialState)

  useEffect(() => {
    setState((prev) => {
      const dispensary = location?.pathname.startsWith('/u/') ?? false
      return prev.dispensary === dispensary ? prev : { dispensary }
    })
  }, [location?.pathname])

  useEffect(() => {
    const subscriptions = [
      publishProvisioningNeededEvent$.subscribe(),
      utilityMenuState$.subscribe(),
    ]
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
  }, [])

  useEffect(() => {
    if (!state.dispensary) {
      return
    }
    const subscriptions = [
      // hold subscription to dispensary (mirrors context value)
      latestDispensary$.subscribe(),
      // hold subscription to slug for use in Sanity dispensary queries
      latestDispensarySlug$.subscribe(),
      dispensaryLogoCdn$.subscribe(),
    ]
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
      // clear dispensary context on unmount. This is important when using browser navigation to exit
      // a dispensary and return to the main site.
      setDispensary(undefined)
    }
  }, [state.dispensary])

  return null
}
