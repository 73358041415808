export interface DecodedJwt {
  _raw: string
  header: Record<string, unknown>
  payload: Record<string, unknown>
  signature: string
}

export function decodeJwt(token: string): DecodedJwt | undefined {
  const parts = token.split('.')

  if (parts.length !== 3) {
    console.warn(`Invalid JWT: does not contain 3 parts`)
    return
  }

  try {
    return {
      _raw: token,
      header: JSON.parse(atob(parts[0])),
      payload: JSON.parse(atob(parts[1])),
      signature: parts[2],
    }
  } catch (err) {
    console.warn('Invalid JWT: unable to parse:', err)
    return
  }
}
