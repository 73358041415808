import React, { useEffect, useReducer } from 'react'

import { ClickWrapModalContext } from '../components/global/clickwrap/context'

export interface ClickWrapModalState {
  isOpen: boolean
}

export interface ClickWrapModalDispatchAction {
  type: 'open' | 'close' | 'toggleOpen'
}

export interface ClickWrapModalContextProviderProps {
  children: React.ReactNode
  dispatchRef?: React.MutableRefObject<React.Dispatch<ClickWrapModalDispatchAction> | null>
}

const ClickWrapModalContextProvider: React.FC<ClickWrapModalContextProviderProps> = ({
  children,
  dispatchRef,
}) => {
  const [state, dispatch] = useReducer(
    (prevState: ClickWrapModalState, action: ClickWrapModalDispatchAction) => {
      switch (action.type) {
        case 'open':
          return { ...prevState, isOpen: true }
        case 'close':
          return { ...prevState, isOpen: false }
        case 'toggleOpen':
          return { ...prevState, isOpen: !prevState.isOpen }
        default:
          console.error(`Invalid action (ClickWrapModalContextProvider): ${JSON.stringify(action)}`)
          return prevState
      }
    },
    { isOpen: false },
    undefined,
  )

  useEffect(() => {
    if (dispatchRef) {
      dispatchRef.current = dispatch
    }
    return () => {
      if (dispatchRef) {
        dispatchRef.current = null
      }
    }
  }, [dispatchRef])

  return (
    <ClickWrapModalContext.Provider value={[state, dispatch]}>
      {children}
    </ClickWrapModalContext.Provider>
  )
}

export default ClickWrapModalContextProvider
