export const contentTypeJson: HeadersInit = {
  'Content-Type': 'application/json',
}

export const contentType = (value: string): HeadersInit => ({
  'Content-Type': value,
})

export const acceptJson: HeadersInit = {
  Accept: 'application/json',
}

export const authorization = (accessToken: string): HeadersInit => ({
  Authorization: `Bearer ${accessToken}`,
})
