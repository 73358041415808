import { useLocation } from '@gatsbyjs/reach-router'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { usePromisify } from '../../../../hooks/usePromisify'
import { useRemoveParamsFromUrl } from '../../../../hooks/useRemoveParamsFromUrl'
import {
  EScriptCartAttributes,
  EScriptParamsData,
  EScriptParamsLoader,
  EScriptUpdateCartAction,
} from '../../types'
import { wellWorldParamsToRemove } from './config'
import {
  getWellWorldParamsFromQuery,
  hasAnyWellWorldIdParam,
  hasValidWellWorldProductsParam,
} from './parser'
import { WellWorldParams } from './types'

export const useWellWorldParamsLoader = (shouldRun = true): EScriptParamsLoader => {
  const [loading, setLoading] = useState(shouldRun)
  const [params, setParams] = useState<WellWorldParams>({})

  const { search, href } = useLocation()
  const link = href
  const removeParamsFromUrl = useRemoveParamsFromUrl()

  const parsedParams = useMemo<WellWorldParams>(() => {
    if (!shouldRun) {
      return {}
    }
    const queryParams = queryString.parse(search || '', { arrayFormat: 'comma' })
    return getWellWorldParamsFromQuery(queryParams)
  }, [shouldRun, search])

  const hasProductParams = useMemo(() => {
    return shouldRun && !loading && hasValidWellWorldProductsParam(params.products)
  }, [shouldRun, loading, params.products])

  const hasWellWorldParams = useMemo(
    () => shouldRun && !loading && hasAnyWellWorldIdParam(params),
    [shouldRun, loading, params],
  )

  const updateCartActions = useMemo<EScriptUpdateCartAction[] | undefined>(() => {
    return hasProductParams && params.products
      ? [{ operation: 'empty' }, { products: params.products, operation: 'add' }]
      : undefined
  }, [hasProductParams, params.products])

  const affiliateId = useMemo(
    () => (hasWellWorldParams && params.wellWorldId ? 'Well World' : undefined),
    [hasWellWorldParams, params.wellWorldId],
  )

  const cartAttributes = useMemo((): EScriptCartAttributes | undefined => {
    if (loading || !hasWellWorldParams) {
      return undefined
    }
    return {
      type: 'wellWorld',
      attributes: {
        wellWorldId: params.wellWorldId,
        wellWorldStoreId: params.wellWorldStoreId,
        wellWorldPracticeId: params.wellWorldPracticeId,
        wellWorldPractitionerId: params.wellWorldPractitionerId,
      },
    }
  }, [
    loading,
    hasWellWorldParams,
    params.wellWorldId,
    params.wellWorldPracticeId,
    params.wellWorldPractitionerId,
    params.wellWorldStoreId,
  ])

  const promoCode = useMemo(
    () => (!loading && hasWellWorldParams ? params.promoCode : undefined),
    [hasWellWorldParams, loading, params.promoCode],
  )

  useEffect(() => {
    if (!shouldRun) {
      return
    }
    const parsedParamsEntries = Object.entries(parsedParams)
    if (parsedParamsEntries.length === 0) {
      return
    }
    setParams((prevParams) =>
      parsedParamsEntries.reduce(
        (acc: WellWorldParams, [key, value]) =>
          // if parsed params are emptied, keep the values in state, but if url params are updated
          // with non-empty values, update the state
          value ? { ...acc, [key]: value } : acc,
        prevParams,
      ),
    )
    setLoading(false)
  }, [parsedParams, shouldRun])

  const paramsData = useMemo(
    (): EScriptParamsData => ({
      version: 'well_world',
      updateCartActions,
      promoCode,
      cartAttributes,
      affiliateId,
      link,
    }),
    [updateCartActions, promoCode, cartAttributes, affiliateId, link],
  )

  const clearParams = useCallback(
    () => (shouldRun ? removeParamsFromUrl(wellWorldParamsToRemove) : undefined),
    [removeParamsFromUrl, shouldRun],
  )

  const { load: loadParams } = usePromisify<EScriptParamsData>({
    loading,
    error: undefined,
    data: paramsData,
  })

  return useMemo(() => ({ loading, loadParams, clearParams }), [clearParams, loadParams, loading])
}
