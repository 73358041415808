export const requestedScopes = [
  'openid',
  'profile',
  'email',
  'magento_user',
  'read:customers',
  'update:customers',
  'accept_agreement:customers',
  'read:drug_nutrient',
  'read:professional_library',
  'purchase:test_kit',
  'read:pharmacy_content',
  'read:e_scripts',
  'write:e_scripts',
]

export const callbackSlugs = {
  login: 'logging-in',
  logout: 'logging-out',
} as const
