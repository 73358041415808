import { Observable, of } from 'rxjs'

export const coerceCaughtToLeft = (error: unknown): Observable<{ _tag: 'Left'; error: Error }> => {
  if (error instanceof Error) {
    return of({ _tag: 'Left', message: error.message, error })
  }
  const message =
    error != null && typeof error === 'object'
      ? (error as Partial<{ message?: unknown }>).message
      : undefined
  return of({
    _tag: 'Left' as const,
    error: new Error(message && typeof message === 'string' ? message : `${error}`),
  })
}
