import { useEffect } from 'react'

import { actions, UseCartGetReducer } from '../useCartGetReducer'

export const useGuestCartAllowedActions = ({
  reducer,
  allowGuestCart,
}: {
  reducer: UseCartGetReducer
  allowGuestCart?: boolean
}): void => {
  const [state, dispatch] = reducer
  const { guestCartAllowed } = state

  // dispatch guest-allowed actions
  useEffect(() => {
    if (allowGuestCart) {
      if (!guestCartAllowed) {
        dispatch(actions.allowGuest())
      }
    } else if (guestCartAllowed) {
      dispatch(actions.disallowGuest())
    }
  }, [dispatch, allowGuestCart, guestCartAllowed])
}
