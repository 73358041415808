import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `DateTimeMillis` scalar type represents a date and time in the format of milliseconds since the Unix epoch.` */
  DateTimeMillis: number
  /**
   * A signed decimal number, which supports arbitrary precision and is serialized as a string.
   * Example values: `"29.99"`, `"29.999"`.
   */
  Decimal: string
}

export type AccountDocument = {
  __typename?: 'AccountDocument'
  /** The type of the document. */
  documentType: AccountDocumentType
  /** The name of the file. */
  fileName: Scalars['String']
  /** The date and time the account document was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

export const AccountDocumentType = {
  TaxExemption: 'TAX_EXEMPTION',
  W9TaxId: 'W9_TAX_ID',
} as const

export type AccountDocumentType = (typeof AccountDocumentType)[keyof typeof AccountDocumentType]
export const AccountType = {
  Checking: 'CHECKING',
  Savings: 'SAVINGS',
} as const

export type AccountType = (typeof AccountType)[keyof typeof AccountType]
export type AutoCompleteInput = {
  limit?: Maybe<Scalars['Int']>
  query: Scalars['String']
}

export type AutoCompleteResults = {
  __typename?: 'AutoCompleteResults'
  limit: Scalars['Int']
  query: Scalars['String']
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
  totalCount: Scalars['Int']
}

/** The auto ship interval options. */
export const AutoShipOption = {
  Months_1: 'MONTHS_1',
  Months_2: 'MONTHS_2',
  Months_3: 'MONTHS_3',
} as const

export type AutoShipOption = (typeof AutoShipOption)[keyof typeof AutoShipOption]
export type CreateEScriptInput = {
  /** The description of the eScript. */
  description?: Maybe<Scalars['String']>
  /** The name of the eScript. */
  name: Scalars['String']
  /**
   * The products in the eScript.
   * The sku should be unique within an eScript.
   */
  products: Array<NewEScriptProduct>
  /**
   * The public promo code that is used by a customer to get the discount.
   * Empty string or null means no promo code.
   */
  promoCode?: Maybe<Scalars['String']>
}

/** The standard ISO 4217 currency codes that represent the currencies used. */
export const CurrencyCode = {
  Cad: 'CAD',
  Usd: 'USD',
} as const

export type CurrencyCode = (typeof CurrencyCode)[keyof typeof CurrencyCode]
export type Customer = {
  __typename?: 'Customer'
  accountDocument: Array<AccountDocument>
  payoutMethod?: Maybe<PayoutMethod>
}

export type DeleteAccountDocumentPayload = {
  __typename?: 'DeleteAccountDocumentPayload'
  /** Date and time the account document was deleted. */
  deletedAt: Scalars['DateTimeMillis']
}

export type DeleteEScriptPayload = {
  __typename?: 'DeleteEScriptPayload'
  /**
   * The id of the removed eScript.
   * If the eScript was not found, this will be null.
   */
  id?: Maybe<Scalars['ID']>
}

export type DeletePayoutMethodPayload = {
  __typename?: 'DeletePayoutMethodPayload'
  /** Date and time the payout method was deleted. */
  deletedAt: Scalars['DateTimeMillis']
}

export type DocumentDescription = {
  __typename?: 'DocumentDescription'
  html?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type EScript = Node & {
  __typename?: 'EScript'
  /** The date and time the eScript was created. */
  createdAt: Scalars['DateTimeMillis']
  /** The description of the eScript. */
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** The name of the eScript. */
  name: Scalars['String']
  /** The products in the eScript. */
  products: Array<EScriptProduct>
  /** The public promo code that is used by a customer to get the discount. */
  promoCode?: Maybe<Scalars['String']>
  /** The date and time the eScript was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

/** The connection type for EScript. */
export type EScriptConnection = {
  __typename?: 'EScriptConnection'
  /** A list of edges. */
  edges: Array<EScriptEdge>
  /** A list of nodes. */
  nodes: Array<EScript>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type EScriptEdge = {
  __typename?: 'EScriptEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: EScript
}

/** Properties by which eScripts can be ordered */
export const EScriptOrderField = {
  /** Order by created timestamp */
  CreatedAt: 'CREATED_AT',
  /** Order by updated timestamp */
  UpdatedAt: 'UPDATED_AT',
} as const

export type EScriptOrderField = (typeof EScriptOrderField)[keyof typeof EScriptOrderField]
/** Options for sorting list of eScripts */
export type EScriptOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: Maybe<OrderDirection>
  /** Order by field */
  field: EScriptOrderField
}

export type EScriptProduct = {
  __typename?: 'EScriptProduct'
  /** Whether the product is a single order or auto ship with the given interval. */
  autoShip?: Maybe<AutoShipOption>
  /** Product details. */
  product: Product
  /** The quantity of the product. */
  quantity: Scalars['Int']
  /** The tags of the product, including the name of the protocols it was added from. */
  tags?: Maybe<Array<Scalars['String']>>
}

export type ExactParentOrChildSkuInput = {
  /** @deprecated(reason: "Unused") */
  limit?: Maybe<Scalars['Int']>
  sku?: Maybe<Array<Scalars['String']>>
}

export type ExactParentOrChildSkuResults = {
  __typename?: 'ExactParentOrChildSkuResults'
  /** @deprecated Unused */
  limit?: Maybe<Scalars['Int']>
  query: Scalars['String']
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
  totalCount: Scalars['Int']
}

export type FacetedInput = {
  filters?: Maybe<FacetedInputFilters>
  keywords?: Maybe<Scalars['String']>
  pagination?: Maybe<FacetedInputPagination>
  sort?: Maybe<FacetedInputSort>
}

export type FacetedInputFilters = {
  ailment?: Maybe<Array<Scalars['ID']>>
  category?: Maybe<Array<Scalars['ID']>>
  ingredient?: Maybe<Array<Scalars['ID']>>
  labelStatus?: Maybe<Array<Scalars['ID']>>
  nameRange?: Maybe<Array<Scalars['ID']>>
  productForm?: Maybe<Array<Scalars['ID']>>
  specialty?: Maybe<Array<Scalars['ID']>>
}

export type FacetedInputPagination = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

export type FacetedInputSort = {
  name?: Maybe<FacetedSortOrder>
}

export type FacetedResults = {
  __typename?: 'FacetedResults'
  aggregations: FacetedResultsAggregations
  keywords?: Maybe<Scalars['String']>
  meta: FacetedResultsMeta
  results: Array<IndexedProduct>
  suggestions: Array<Scalars['String']>
}

export type FacetedResultsAggregation = {
  __typename?: 'FacetedResultsAggregation'
  id: Scalars['String']
  label?: Maybe<Scalars['String']>
  options: Array<FacetedResultsAggregationOption>
}

export type FacetedResultsAggregationOption = {
  __typename?: 'FacetedResultsAggregationOption'
  count?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  value: Scalars['ID']
}

export type FacetedResultsAggregations = {
  __typename?: 'FacetedResultsAggregations'
  ailment?: Maybe<FacetedResultsAggregation>
  category?: Maybe<FacetedResultsAggregation>
  ingredient?: Maybe<FacetedResultsAggregation>
  labelStatus?: Maybe<FacetedResultsAggregation>
  nameRange?: Maybe<FacetedResultsAggregation>
  productForm?: Maybe<FacetedResultsAggregation>
  specialty?: Maybe<FacetedResultsAggregation>
}

export type FacetedResultsMeta = {
  __typename?: 'FacetedResultsMeta'
  count: FacetedResultsMetaCount
  pagination: FacetedResultsMetaPagination
}

export type FacetedResultsMetaCount = {
  __typename?: 'FacetedResultsMetaCount'
  /** Total number of results */
  all: Scalars['Int']
  /** Number of results in current response */
  current: Scalars['Int']
}

export type FacetedResultsMetaPagination = {
  __typename?: 'FacetedResultsMetaPagination'
  currentPage: Scalars['Int']
  pageSize: Scalars['Int']
  totalPages: Scalars['Int']
}

export const FacetedSortOrder = {
  Asc: 'asc',
  Desc: 'desc',
} as const

export type FacetedSortOrder = (typeof FacetedSortOrder)[keyof typeof FacetedSortOrder]
/** Rich text presented as HTML or plain text. */
export type HtmlOrText = {
  __typename?: 'HtmlOrText'
  html?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
}

export type IndexedDocument = {
  id: Scalars['String']
  name: Scalars['String']
}

export type IndexedProduct = IndexedDocument & {
  __typename?: 'IndexedProduct'
  advisorTags: Array<Scalars['String']>
  ailments: Array<Scalars['String']>
  description: DocumentDescription
  id: Scalars['String']
  ingredients: Array<Scalars['String']>
  /** Maximum label status of child products */
  labelStatus?: Maybe<ProductLabelStatus>
  name: Scalars['String']
  priceRange?: Maybe<MoneyRange>
  retailPriceRange?: Maybe<MoneyRange>
  sanityImageJson?: Maybe<Scalars['String']>
  sanityImageUrl?: Maybe<Scalars['String']>
  shortDescription: DocumentDescription
  shortDescriptionV2?: Maybe<Scalars['String']>
  sku: Scalars['String']
  stockStatus?: Maybe<IndexedProductStockStatus>
  tagline?: Maybe<Scalars['String']>
  /** @deprecated Use `name` */
  title: Scalars['String']
  urlKey: Scalars['String']
  variants?: Maybe<IndexedProductVariants>
}

export const IndexedProductStockStatus = {
  InStock: 'inStock',
  OutOfStock: 'outOfStock',
} as const

export type IndexedProductStockStatus =
  (typeof IndexedProductStockStatus)[keyof typeof IndexedProductStockStatus]
export const IndexedProductVariantAttribute = {
  Dosage: 'dosage',
  Flavor: 'flavor',
  FlavorSize: 'flavorSize',
  Size: 'size',
} as const

export type IndexedProductVariantAttribute =
  (typeof IndexedProductVariantAttribute)[keyof typeof IndexedProductVariantAttribute]
export type IndexedProductVariants = {
  __typename?: 'IndexedProductVariants'
  summary: IndexedProductVariantsSummary
}

export type IndexedProductVariantsSummary = {
  __typename?: 'IndexedProductVariantsSummary'
  attributes: Array<IndexedProductVariantAttribute>
  values: IndexedProductVariantsSummaryValues
}

export type IndexedProductVariantsSummaryValues = {
  __typename?: 'IndexedProductVariantsSummaryValues'
  dosage?: Maybe<Array<Scalars['String']>>
  flavor?: Maybe<Array<Scalars['String']>>
  flavorSize?: Maybe<Array<Scalars['String']>>
  size?: Maybe<Array<Scalars['String']>>
}

export type Money = {
  __typename?: 'Money'
  /** Decimal money amount. */
  amount: Scalars['Decimal']
  /** Currency of the money. */
  currencyCode: CurrencyCode
}

export type MoneyRange = {
  __typename?: 'MoneyRange'
  /** Currency of the money range. */
  currencyCode: CurrencyCode
  /** The highest price in the range. */
  maximum: Scalars['Decimal']
  /** The lowest price in the range. */
  minimum: Scalars['Decimal']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Create a new eScript. */
  createEScript?: Maybe<EScript>
  /** Delete the account document for the customer. */
  deleteAccountDocument?: Maybe<DeleteAccountDocumentPayload>
  /** Delete eScript with the given id. */
  deleteEScript: DeleteEScriptPayload
  /** Delete the payout method for the customer. */
  deletePayoutMethod?: Maybe<DeletePayoutMethodPayload>
  /** Update eScript with the given id. */
  updateEScript?: Maybe<EScript>
  /** Update the payout method for the customer. */
  updatePayoutMethodAch?: Maybe<PayoutMethodAch>
  /** Upload a document for the customer account. */
  uploadAccountDocument?: Maybe<AccountDocument>
}

export type MutationCreateEScriptArgs = {
  input: CreateEScriptInput
}

export type MutationDeleteAccountDocumentArgs = {
  documentType: AccountDocumentType
}

export type MutationDeleteEScriptArgs = {
  id: Scalars['ID']
}

export type MutationUpdateEScriptArgs = {
  input: UpdateEScriptInput
}

export type MutationUpdatePayoutMethodAchArgs = {
  input: UpdatePayoutMethodAchInput
}

export type MutationUploadAccountDocumentArgs = {
  input: UploadAccountDocumentInput
}

export type NewEScriptProduct = {
  /** No autoShip means single order. */
  autoShip?: Maybe<AutoShipOption>
  /** Quantity should be greater than 0. */
  quantity: Scalars['Int']
  /** Sku should be unique within an eScript. */
  sku: Scalars['String']
  /** No tags means remove existing when updating. */
  tags?: Maybe<Array<Scalars['String']>>
}

export type Node = {
  id: Scalars['ID']
}

/** Possible directions in which to order a list of items */
export const OrderDirection = {
  /** Ascending */
  Asc: 'ASC',
  /** Descending */
  Desc: 'DESC',
} as const

export type OrderDirection = (typeof OrderDirection)[keyof typeof OrderDirection]
/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type ParentProduct = {
  __typename?: 'ParentProduct'
  /** Extended product description */
  description?: Maybe<HtmlOrText>
  id: Scalars['ID']
  mainImage?: Maybe<SanityImage>
  name: Scalars['String']
  /** Products grouped under this parent product */
  products: ProductConnection
  /** Product description */
  shortDescription?: Maybe<HtmlOrText>
  sku: Scalars['String']
  urlKey: Scalars['String']
}

export type ParentProductProductsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProductOrdering>>
}

export type ParentProductConnection = {
  __typename?: 'ParentProductConnection'
  /** A list of edges. */
  edges: Array<ParentProductEdge>
  /** A list of nodes. */
  nodes: Array<ParentProduct>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ParentProductEdge = {
  __typename?: 'ParentProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: ParentProduct
}

/** Properties by which parent products can be ordered */
export const ParentProductOrderField = {
  /** Order by label status (maximum status of child products) */
  LabelStatus: 'LABEL_STATUS',
  /** Order by name */
  Name: 'NAME',
  /** Order by score/relevance */
  Score: 'SCORE',
} as const

export type ParentProductOrderField =
  (typeof ParentProductOrderField)[keyof typeof ParentProductOrderField]
/** Options for sorting list of parent products */
export type ParentProductOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: Maybe<OrderDirection>
  /** "Order by" field */
  field: ParentProductOrderField
}

export type PayoutMethod = {
  /** The date and time the payout method was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

export type PayoutMethodAch = PayoutMethod & {
  __typename?: 'PayoutMethodAch'
  /** The name of the account holder. */
  accountHolderName: Scalars['String']
  /** Last 4 digits of the account number. */
  accountNumber: Scalars['String']
  /** The type of account. */
  accountType: AccountType
  /** The name of the bank. */
  bankName: Scalars['String']
  /** The routing number of the bank. */
  routingNumber: Scalars['String']
  /** The date and time the payout method was last updated. */
  updatedAt: Scalars['DateTimeMillis']
}

/** Product (or variant) - a distinct item for sale. */
export type Product = {
  __typename?: 'Product'
  id: Scalars['ID']
  /** Get label status for this product */
  labelStatus: ProductLabelStatus
  /** Main image for this product */
  mainImage?: Maybe<SanityImage>
  name: Scalars['String']
  /** Get parent product for this product */
  parentProduct: ParentProduct
  /** Get price for this product */
  price?: Maybe<Money>
  /** Get retail price for this product */
  retailPrice?: Maybe<Money>
  sku: Scalars['String']
}

/** Product (or variant) - a distinct item for sale. */
export type ProductLabelStatusArgs = {
  labelName: Scalars['String']
}

export type ProductConnection = {
  __typename?: 'ProductConnection'
  /** A list of edges. */
  edges: Array<ProductEdge>
  /** A list of nodes. */
  nodes: Array<Product>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Product
}

/** Label status for a given product */
export const ProductLabelStatus = {
  /** No label */
  Absent: 'ABSENT',
  /** Label present */
  Present: 'PRESENT',
} as const

export type ProductLabelStatus = (typeof ProductLabelStatus)[keyof typeof ProductLabelStatus]
/** Properties by which products can be ordered */
export const ProductOrderField = {
  /** Order by name */
  Name: 'NAME',
  /** Order by SKU */
  Sku: 'SKU',
} as const

export type ProductOrderField = (typeof ProductOrderField)[keyof typeof ProductOrderField]
/** Options for sorting list of products */
export type ProductOrdering = {
  /** Sort direction - default to field's natural direction */
  direction?: Maybe<OrderDirection>
  /** "Order by" field */
  field: ProductOrderField
}

/** Merged Query object */
export type Query = {
  __typename?: 'Query'
  autoComplete: AutoCompleteResults
  customer?: Maybe<Customer>
  /** Get an eScript by ID. */
  eScript?: Maybe<EScript>
  /** A list of eScripts created by the user. */
  eScripts: EScriptConnection
  exact: ExactParentOrChildSkuResults
  faceted: FacetedResults
  /** Get a parent product by ID */
  parentProduct?: Maybe<ParentProduct>
  /** Get a parent product by SKU */
  parentProductBySku?: Maybe<ParentProduct>
  /** Get a list of parent products */
  parentProducts: ParentProductConnection
  /** Get a product by ID */
  product?: Maybe<Product>
  /** Get a product by SKU */
  productBySku?: Maybe<Product>
  /** Get a list of products */
  products: ProductConnection
}

/** Merged Query object */
export type QueryAutoCompleteArgs = {
  enableFullCatalog?: Maybe<Scalars['Boolean']>
  input?: Maybe<AutoCompleteInput>
  limit?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['String']>
}

/** Merged Query object */
export type QueryEScriptArgs = {
  id: Scalars['ID']
}

/** Merged Query object */
export type QueryEScriptsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<EScriptOrdering>>
  query?: Maybe<Scalars['String']>
}

/** Merged Query object */
export type QueryExactArgs = {
  enableFullCatalog?: Maybe<Scalars['Boolean']>
  input?: Maybe<ExactParentOrChildSkuInput>
  sku?: Maybe<Array<Scalars['String']>>
}

/** Merged Query object */
export type QueryFacetedArgs = {
  enableFullCatalog?: Maybe<Scalars['Boolean']>
  filters?: Maybe<FacetedInputFilters>
  input?: Maybe<FacetedInput>
  keywords?: Maybe<Scalars['String']>
  orderBy?: Maybe<Array<ParentProductOrdering>>
  pagination?: Maybe<FacetedInputPagination>
}

/** Merged Query object */
export type QueryParentProductArgs = {
  id: Scalars['ID']
}

/** Merged Query object */
export type QueryParentProductBySkuArgs = {
  sku: Scalars['String']
}

/** Merged Query object */
export type QueryParentProductsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  labelName?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ParentProductOrdering>>
  query?: Maybe<Scalars['String']>
  skus?: Maybe<Array<Scalars['String']>>
}

/** Merged Query object */
export type QueryProductArgs = {
  id: Scalars['ID']
}

/** Merged Query object */
export type QueryProductBySkuArgs = {
  sku: Scalars['String']
}

/** Merged Query object */
export type QueryProductsArgs = {
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  orderBy?: Maybe<Array<ProductOrdering>>
  skus?: Maybe<Array<Scalars['String']>>
}

/** Sanity image indexed in Solr */
export type SanityImage = {
  __typename?: 'SanityImage'
  json: Scalars['String']
  url: Scalars['String']
}

export type UpdateEScriptInput = {
  /** The id of the eScript to update. */
  id: Scalars['ID']
  /**
   * The products in the eScript.
   * The sku should be unique within an eScript.
   */
  products?: Maybe<Array<NewEScriptProduct>>
  /**
   * The public promo code that is used by a customer to get the discount.
   * Empty string means no promo code, null means no change.
   */
  promoCode?: Maybe<Scalars['String']>
}

export type UpdatePayoutMethodAchInput = {
  /** The name of the account holder. */
  accountHolderName?: Maybe<Scalars['String']>
  /** The full account number. */
  accountNumber?: Maybe<Scalars['String']>
  /** The type of account. */
  accountType?: Maybe<AccountType>
  /** The name of the bank. */
  bankName?: Maybe<Scalars['String']>
  /** The routing number of the bank. */
  routingNumber?: Maybe<Scalars['String']>
}

export type UploadAccountDocumentInput = {
  /** The type of the document. */
  documentType: AccountDocumentType
  /**
   * The url of the document.
   * Example: "s3://some-upload-bucket/path/to/file.pdf"
   */
  documentUrl: Scalars['String']
  /** The name of the file. */
  fileName: Scalars['String']
}

export type SearchAccountDocumentQueryVariables = Exact<{ [key: string]: never }>

export type SearchAccountDocumentQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      accountDocument: Array<
        { __typename?: 'AccountDocument' } & Pick<
          AccountDocument,
          'documentType' | 'fileName' | 'updatedAt'
        >
      >
    }
  >
}

export type SearchAutoCompleteQueryVariables = Exact<{
  query: Scalars['String']
  limit?: Maybe<Scalars['Int']>
}>

export type SearchAutoCompleteQuery = { __typename?: 'Query' } & {
  autoComplete: { __typename?: 'AutoCompleteResults' } & Pick<
    AutoCompleteResults,
    'query' | 'limit' | 'suggestions' | 'totalCount'
  > & {
      results: Array<
        { __typename?: 'IndexedProduct' } & Pick<
          IndexedProduct,
          'sanityImageJson' | 'sku' | 'id' | 'name' | 'urlKey' | 'labelStatus'
        > & {
            shortDescription: { __typename?: 'DocumentDescription' } & Pick<
              DocumentDescription,
              'html' | 'text'
            >
          }
      >
    }
}

export type CreateEScriptMutationVariables = Exact<{
  input: CreateEScriptInput
}>

export type CreateEScriptMutation = { __typename?: 'Mutation' } & {
  createEScript?: Maybe<
    { __typename?: 'EScript' } & Pick<
      EScript,
      'id' | 'createdAt' | 'description' | 'name' | 'promoCode' | 'updatedAt'
    > & {
        products: Array<
          { __typename?: 'EScriptProduct' } & Pick<
            EScriptProduct,
            'autoShip' | 'quantity' | 'tags'
          > & {
              product: { __typename?: 'Product' } & Pick<Product, 'id' | 'sku' | 'name'> & {
                  mainImage?: Maybe<{ __typename?: 'SanityImage' } & Pick<SanityImage, 'url'>>
                }
            }
        >
      }
  >
}

export type DeleteAccountDocumentMutationVariables = Exact<{
  documentType: AccountDocumentType
}>

export type DeleteAccountDocumentMutation = { __typename?: 'Mutation' } & {
  deleteAccountDocument?: Maybe<
    { __typename?: 'DeleteAccountDocumentPayload' } & Pick<
      DeleteAccountDocumentPayload,
      'deletedAt'
    >
  >
}

export type DeleteEScriptMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteEScriptMutation = { __typename?: 'Mutation' } & {
  deleteEScript: { __typename?: 'DeleteEScriptPayload' } & Pick<DeleteEScriptPayload, 'id'>
}

export type DeletePayoutMethodMutationVariables = Exact<{ [key: string]: never }>

export type DeletePayoutMethodMutation = { __typename?: 'Mutation' } & {
  deletePayoutMethod?: Maybe<
    { __typename?: 'DeletePayoutMethodPayload' } & Pick<DeletePayoutMethodPayload, 'deletedAt'>
  >
}

export type EScriptQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type EScriptQuery = { __typename?: 'Query' } & {
  eScript?: Maybe<
    { __typename?: 'EScript' } & Pick<EScript, 'id' | 'description' | 'promoCode' | 'name'> & {
        products: Array<
          { __typename?: 'EScriptProduct' } & Pick<
            EScriptProduct,
            'quantity' | 'tags' | 'autoShip'
          > & {
              product: { __typename?: 'Product' } & Pick<Product, 'id' | 'sku' | 'name'> & {
                  mainImage?: Maybe<{ __typename?: 'SanityImage' } & Pick<SanityImage, 'url'>>
                  parentProduct: { __typename?: 'ParentProduct' } & Pick<ParentProduct, 'urlKey'>
                }
            }
        >
      }
  >
}

export type SearchEScriptsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>
  direction?: Maybe<OrderDirection>
  query?: Maybe<Scalars['String']>
}>

export type SearchEScriptsQuery = { __typename?: 'Query' } & {
  eScripts: { __typename?: 'EScriptConnection' } & {
    edges: Array<
      { __typename?: 'EScriptEdge' } & {
        node: { __typename?: 'EScript' } & Pick<
          EScript,
          'id' | 'description' | 'name' | 'updatedAt' | 'promoCode'
        > & {
            products: Array<
              { __typename?: 'EScriptProduct' } & Pick<EScriptProduct, 'autoShip' | 'quantity'> & {
                  product: { __typename?: 'Product' } & Pick<Product, 'sku'>
                }
            >
          }
      }
    >
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type SearchExactParentOrChildSkuQueryVariables = Exact<{
  sku?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type SearchExactParentOrChildSkuQuery = { __typename?: 'Query' } & {
  exact: { __typename?: 'ExactParentOrChildSkuResults' } & Pick<
    ExactParentOrChildSkuResults,
    'query' | 'limit' | 'suggestions' | 'totalCount'
  > & {
      results: Array<
        { __typename?: 'IndexedProduct' } & Pick<
          IndexedProduct,
          | 'advisorTags'
          | 'labelStatus'
          | 'sanityImageJson'
          | 'sanityImageUrl'
          | 'sku'
          | 'stockStatus'
          | 'id'
          | 'name'
          | 'urlKey'
        > & {
            shortDescription: { __typename?: 'DocumentDescription' } & Pick<
              DocumentDescription,
              'html' | 'text'
            >
            description: { __typename?: 'DocumentDescription' } & Pick<DocumentDescription, 'html'>
            variants?: Maybe<
              { __typename?: 'IndexedProductVariants' } & {
                summary: { __typename?: 'IndexedProductVariantsSummary' } & Pick<
                  IndexedProductVariantsSummary,
                  'attributes'
                > & {
                    values: { __typename?: 'IndexedProductVariantsSummaryValues' } & Pick<
                      IndexedProductVariantsSummaryValues,
                      'size' | 'dosage' | 'flavor' | 'flavorSize'
                    >
                  }
              }
            >
            priceRange?: Maybe<
              { __typename?: 'MoneyRange' } & Pick<
                MoneyRange,
                'currencyCode' | 'maximum' | 'minimum'
              >
            >
            retailPriceRange?: Maybe<
              { __typename?: 'MoneyRange' } & Pick<
                MoneyRange,
                'currencyCode' | 'maximum' | 'minimum'
              >
            >
          }
      >
    }
}

export type SearchFacetedQueryVariables = Exact<{
  filters?: Maybe<FacetedInputFilters>
  keywords?: Maybe<Scalars['String']>
  orderBy?: Maybe<Array<ParentProductOrdering> | ParentProductOrdering>
  pagination?: Maybe<FacetedInputPagination>
}>

export type SearchFacetedQuery = { __typename?: 'Query' } & {
  faceted: { __typename?: 'FacetedResults' } & Pick<FacetedResults, 'suggestions'> & {
      meta: { __typename?: 'FacetedResultsMeta' } & {
        count: { __typename?: 'FacetedResultsMetaCount' } & Pick<
          FacetedResultsMetaCount,
          'all' | 'current'
        >
        pagination: { __typename?: 'FacetedResultsMetaPagination' } & Pick<
          FacetedResultsMetaPagination,
          'totalPages' | 'currentPage' | 'pageSize'
        >
      }
      aggregations: { __typename?: 'FacetedResultsAggregations' } & {
        category?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
        labelStatus?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
        nameRange?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
        productForm?: Maybe<
          { __typename?: 'FacetedResultsAggregation' } & Pick<
            FacetedResultsAggregation,
            'id' | 'label'
          > & {
              options: Array<
                { __typename?: 'FacetedResultsAggregationOption' } & Pick<
                  FacetedResultsAggregationOption,
                  'label' | 'value' | 'count'
                >
              >
            }
        >
      }
      results: Array<
        { __typename?: 'IndexedProduct' } & Pick<
          IndexedProduct,
          | 'id'
          | 'name'
          | 'urlKey'
          | 'advisorTags'
          | 'labelStatus'
          | 'sanityImageJson'
          | 'sanityImageUrl'
          | 'sku'
          | 'stockStatus'
        > & {
            priceRange?: Maybe<
              { __typename?: 'MoneyRange' } & Pick<
                MoneyRange,
                'currencyCode' | 'maximum' | 'minimum'
              >
            >
            retailPriceRange?: Maybe<
              { __typename?: 'MoneyRange' } & Pick<
                MoneyRange,
                'currencyCode' | 'maximum' | 'minimum'
              >
            >
            shortDescription: { __typename?: 'DocumentDescription' } & Pick<
              DocumentDescription,
              'html'
            >
            description: { __typename?: 'DocumentDescription' } & Pick<DocumentDescription, 'html'>
            variants?: Maybe<
              { __typename?: 'IndexedProductVariants' } & {
                summary: { __typename?: 'IndexedProductVariantsSummary' } & Pick<
                  IndexedProductVariantsSummary,
                  'attributes'
                > & {
                    values: { __typename?: 'IndexedProductVariantsSummaryValues' } & Pick<
                      IndexedProductVariantsSummaryValues,
                      'size' | 'dosage' | 'flavor' | 'flavorSize'
                    >
                  }
              }
            >
          }
      >
    }
}

export type NewEScriptFragment = { __typename: 'EScript' } & Pick<EScript, 'id'>

export type SearchParentProductBySkuQueryVariables = Exact<{
  sku: Scalars['String']
  labelName: Scalars['String']
  includeLabel: Scalars['Boolean']
}>

export type SearchParentProductBySkuQuery = { __typename?: 'Query' } & {
  parentProductBySku?: Maybe<
    { __typename?: 'ParentProduct' } & Pick<ParentProduct, 'id' | 'name'> & {
        products: { __typename?: 'ProductConnection' } & {
          edges: Array<
            { __typename?: 'ProductEdge' } & Pick<ProductEdge, 'cursor'> & {
                node: { __typename?: 'Product' } & MakeOptional<
                  Pick<Product, 'id' | 'labelStatus' | 'name' | 'sku'>,
                  'labelStatus'
                > & {
                    price?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'currencyCode' | 'amount'>>
                    retailPrice?: Maybe<
                      { __typename?: 'Money' } & Pick<Money, 'currencyCode' | 'amount'>
                    >
                  }
              }
          >
        }
      }
  >
}

export type PayoutMethodAchQueryVariables = Exact<{ [key: string]: never }>

export type PayoutMethodAchQuery = { __typename?: 'Query' } & {
  customer?: Maybe<
    { __typename?: 'Customer' } & {
      payoutMethod?: Maybe<
        { __typename?: 'PayoutMethodAch' } & Pick<
          PayoutMethodAch,
          | 'accountNumber'
          | 'bankName'
          | 'accountType'
          | 'accountHolderName'
          | 'routingNumber'
          | 'updatedAt'
        >
      >
    }
  >
}

export type ProductBySkuWithLabelStatusQueryVariables = Exact<{
  sku: Scalars['String']
  labelName: Scalars['String']
}>

export type ProductBySkuWithLabelStatusQuery = { __typename?: 'Query' } & {
  productBySku?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'id' | 'labelStatus' | 'name' | 'sku'>
  >
}

export type SearchProductBySkuQueryVariables = Exact<{
  sku: Scalars['String']
}>

export type SearchProductBySkuQuery = { __typename?: 'Query' } & {
  productBySku?: Maybe<
    { __typename?: 'Product' } & Pick<Product, 'id' | 'sku'> & {
        price?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'amount' | 'currencyCode'>>
        retailPrice?: Maybe<{ __typename?: 'Money' } & Pick<Money, 'amount' | 'currencyCode'>>
        mainImage?: Maybe<{ __typename?: 'SanityImage' } & Pick<SanityImage, 'json' | 'url'>>
      }
  >
}

export type UpdateEScriptMutationVariables = Exact<{
  input: UpdateEScriptInput
}>

export type UpdateEScriptMutation = { __typename?: 'Mutation' } & {
  updateEScript?: Maybe<
    { __typename?: 'EScript' } & Pick<
      EScript,
      'id' | 'createdAt' | 'description' | 'name' | 'promoCode' | 'updatedAt'
    > & {
        products: Array<
          { __typename?: 'EScriptProduct' } & Pick<
            EScriptProduct,
            'autoShip' | 'quantity' | 'tags'
          > & {
              product: { __typename?: 'Product' } & Pick<Product, 'id' | 'sku' | 'name'> & {
                  mainImage?: Maybe<{ __typename?: 'SanityImage' } & Pick<SanityImage, 'url'>>
                }
            }
        >
      }
  >
}

export type UpdatePayoutMethodAchMutationVariables = Exact<{
  input: UpdatePayoutMethodAchInput
}>

export type UpdatePayoutMethodAchMutation = { __typename?: 'Mutation' } & {
  updatePayoutMethodAch?: Maybe<
    { __typename?: 'PayoutMethodAch' } & Pick<
      PayoutMethodAch,
      | 'accountNumber'
      | 'bankName'
      | 'accountType'
      | 'accountHolderName'
      | 'routingNumber'
      | 'updatedAt'
    >
  >
}

export type UploadAccountDocumentMutationVariables = Exact<{
  input: UploadAccountDocumentInput
}>

export type UploadAccountDocumentMutation = { __typename?: 'Mutation' } & {
  uploadAccountDocument?: Maybe<
    { __typename?: 'AccountDocument' } & Pick<
      AccountDocument,
      'documentType' | 'fileName' | 'updatedAt'
    >
  >
}

export const NewEScriptFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NewEScript' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EScript' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewEScriptFragment, unknown>
export const SearchAccountDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchAccountDocument' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountDocument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAccountDocumentQuery, SearchAccountDocumentQueryVariables>
export const SearchAutoCompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchAutoComplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoComplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'IndexedProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAutoCompleteQuery, SearchAutoCompleteQueryVariables>
export const CreateEScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateEScriptInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEScriptMutation, CreateEScriptMutationVariables>
export const DeleteAccountDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAccountDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountDocumentType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAccountDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'documentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAccountDocumentMutation, DeleteAccountDocumentMutationVariables>
export const DeleteEScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteEScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEScriptMutation, DeleteEScriptMutationVariables>
export const DeletePayoutMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePayoutMethod' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePayoutMethod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePayoutMethodMutation, DeletePayoutMethodMutationVariables>
export const EScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentProduct' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EScriptQuery, EScriptQueryVariables>
export const SearchEScriptsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchEScripts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'direction' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderDirection' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eScripts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'field' },
                          value: { kind: 'EnumValue', value: 'UPDATED_AT' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'direction' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'direction' } },
                        },
                      ],
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '10' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchEScriptsQuery, SearchEScriptsQueryVariables>
export const SearchExactParentOrChildSkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchExactParentOrChildSku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'query' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retailPriceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'IndexedProduct' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'html' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'advisorTags' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sanityImageUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stockStatus' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variants' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'values' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'dosage' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flavor' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'flavorSize' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchExactParentOrChildSkuQuery,
  SearchExactParentOrChildSkuQueryVariables
>
export const SearchFacetedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchFaceted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FacetedInputFilters' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ParentProductOrdering' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'FacetedInputPagination' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'faceted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableFullCatalog' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'keywords' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'count' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'all' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'totalPages' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currentPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nameRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productForm' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'options' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlKey' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retailPriceRange' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maximum' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minimum' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'html' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'advisorTags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sanityImageJson' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sanityImageUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'dosage' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'flavor' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'flavorSize' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchFacetedQuery, SearchFacetedQueryVariables>
export const SearchParentProductBySkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchParentProductBySku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLabel' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentProductBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'labelStatus' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'labelName' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'labelName' },
                                        },
                                      },
                                    ],
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: { kind: 'Name', value: 'include' },
                                        arguments: [
                                          {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'if' },
                                            value: {
                                              kind: 'Variable',
                                              name: { kind: 'Name', value: 'includeLabel' },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currencyCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'retailPrice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currencyCode' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchParentProductBySkuQuery, SearchParentProductBySkuQueryVariables>
export const PayoutMethodAchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'payoutMethodAch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payoutMethod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PayoutMethodAch' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayoutMethodAchQuery, PayoutMethodAchQueryVariables>
export const ProductBySkuWithLabelStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'productBySkuWithLabelStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'labelName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'labelName' } },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductBySkuWithLabelStatusQuery,
  ProductBySkuWithLabelStatusQueryVariables
>
export const SearchProductBySkuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchProductBySku' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productBySku' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sku' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'retailPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'json' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchProductBySkuQuery, SearchProductBySkuQueryVariables>
export const UpdateEScriptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEScript' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateEScriptInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEScript' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'autoShip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'promoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEScriptMutation, UpdateEScriptMutationVariables>
export const UpdatePayoutMethodAchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePayoutMethodAch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePayoutMethodAchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePayoutMethodAch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountHolderName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'routingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePayoutMethodAchMutation, UpdatePayoutMethodAchMutationVariables>
export const UploadAccountDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadAccountDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UploadAccountDocumentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadAccountDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadAccountDocumentMutation, UploadAccountDocumentMutationVariables>
