import { useLocation } from '@gatsbyjs/reach-router'
import { parseISO } from 'date-fns'
import queryString from 'query-string'
import { useMemo } from 'react'

import { eScript20230411Version, EScriptVersion, wellWorldVersion } from '../config'
import { EScriptParamsLoader } from '../types'
import { useEScript20230411ParamsLoader } from '../versions/2023-04-11/useEScript20230411ParamsLoader'
import { useWellWorldParamsLoader } from '../versions/well-world/useWellWorldParamsLoader'

const isEScriptDateVersion = (versionParam: string | null): versionParam is string =>
  !!versionParam && /^\d{4}-\d{2}-\d{2}$/.test(versionParam)

const isEScript20230411Version = (versionParam: string | null) => {
  return isEScriptDateVersion(versionParam)
    ? parseISO(versionParam).getTime() >= parseISO(eScript20230411Version).getTime()
    : false
}

export const useEScriptParamsLoader = (): EScriptParamsLoader => {
  const { search } = useLocation()

  const version = useMemo((): EScriptVersion => {
    const { v: versionParam } = queryString.parse(search || '', { arrayFormat: 'comma' })
    const versionString = Array.isArray(versionParam) ? versionParam[0] : versionParam
    return isEScript20230411Version(versionString) ? eScript20230411Version : wellWorldVersion
  }, [search])

  const shouldUseEScript20230411 = version === eScript20230411Version

  const eScript20230411ParamsLoader = useEScript20230411ParamsLoader(shouldUseEScript20230411)
  const wellWorldParamsLoader = useWellWorldParamsLoader(!shouldUseEScript20230411)

  if (shouldUseEScript20230411) {
    return eScript20230411ParamsLoader
  }
  return wellWorldParamsLoader
}
