import type { LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-react'

import { Dispensary as DispensaryData } from '../../../graphql/camel'
import { getUrlForNormalizedPath, normalizePath, stringStartsWith } from '../../pages/utils'
import { dispensaryBasePathPrefix, dispensaryLogoutPath } from '../config'
import { Dispensary } from '../types'

export const getDispensaryBasePath = (slug: string): string => {
  return `/${dispensaryBasePathPrefix}${normalizePath(slug)}`
}

/**
 * Add dynamic segment to router base path (if within dispensary) to support case-insensitive slugs
 */
export const getRouterBasePath: (path: string, isDispensary: boolean) => string = (
  path,
  isDispensary,
) =>
  isDispensary ? `/${dispensaryBasePathPrefix}/:slug${normalizePath(path)}` : normalizePath(path)

// not exported, to ensure `addDispensaryBasePathToPath` is used instead
const getDispensaryPagePath = (dispensarySlug: string, pageSlug?: string | null): string => {
  return `${getDispensaryBasePath(dispensarySlug)}${normalizePath(pageSlug)}`
}

export const addDispensaryBasePathToPath = (
  dispensarySlug: string,
  path?: string | null,
): string => {
  const basePath = getDispensaryBasePath(dispensarySlug)
  if (!path) {
    return basePath
  }
  const normalizedPath = normalizePath(path)
  if (stringStartsWith(normalizedPath, basePath, { ignoreCase: true })) {
    return normalizedPath
  }
  return getDispensaryPagePath(dispensarySlug, normalizedPath)
}

const captureDispensarySlugRegex = new RegExp(`^/${dispensaryBasePathPrefix}/([-\\w]+)`)

export const getDispensarySlugFromPath = (path: string): string | undefined => {
  const matches = normalizePath(path).match(captureDispensarySlugRegex)
  if (!matches) {
    return undefined
  }
  const [, dispensarySlug] = matches
  return dispensarySlug
}

const dispensaryPrefixAndSlugRegex = new RegExp(`^/${dispensaryBasePathPrefix}/[-\\w]+`)

export const removeDispensaryPrefixAndSlug = (path: string): string => {
  return path.replace(dispensaryPrefixAndSlugRegex, '')
}

const captureDispensaryPathPartsRegex = new RegExp(
  `^(/${dispensaryBasePathPrefix}/)([-\\w]+)(/.*)?`,
)

export const replaceDispensarySlug = (path: string, dispensarySlug: string): string => {
  return path.replace(captureDispensaryPathPartsRegex, `$1${dispensarySlug}$3`)
}

export const replaceCurrentDispensarySlug = (dispensarySlug: string): string => {
  const currentPath = window.location.href.replace(window.location.origin, '')
  return replaceDispensarySlug(currentPath, dispensarySlug)
}

export const isValidDispensaryPath = (path: string): boolean => {
  const normalizedPath = normalizePath(path)
  return !!normalizedPath && dispensaryPrefixAndSlugRegex.test(normalizedPath)
}

export const addDispensaryBasePathToUrl = (dispensarySlug: string, url?: string | null): string => {
  if (!url) {
    return getUrlForNormalizedPath('')
  }
  const { origin, pathname, search, hash } = new URL(url)
  const path = `${pathname}${search}${hash}`
  const dispensaryPath = addDispensaryBasePathToPath(dispensarySlug, path)
  return `${origin}${dispensaryPath}`
}

export const mergeDispensaryLoginOptions = (
  dispensarySlug: string,
  options?: RedirectLoginOptions,
): RedirectLoginOptions => {
  const optionsObj = options || {}
  return {
    ...optionsObj,
    appState: {
      ...(optionsObj.appState || {}),
      returnTo: addDispensaryBasePathToPath(dispensarySlug, optionsObj.appState?.returnTo),
    },
  }
}

export const mergeDispensaryLogoutOptions = (
  dispensarySlug: string,
  options?: LogoutOptions,
): LogoutOptions => {
  const optionsObj = options || {}
  const dispensaryPath = getDispensaryBasePath(dispensarySlug)
  const logoutPath = normalizePath(
    `${dispensaryLogoutPath}?path=${encodeURIComponent(dispensaryPath)}`,
  )
  const logoutUrl = getUrlForNormalizedPath(logoutPath)
  return {
    ...optionsObj,
    returnTo: logoutUrl,
  }
}

export const getDispensaryFromData = (data: DispensaryData): Dispensary => {
  return {
    isInvalid: false,
    ...data,
    practitionerCodeForOrder: data.practitionerCode,
    practitionerCodeForWellWorldOrder: data.practitionerCode,
  }
}

/**
 * Remove all characters that are not alphanumeric, dashes or underscores
 */
export const sanitizeUrlSlug = (dirtyUrlSlug: string): string => {
  const regex = /[^a-zA-Z0-9_-]/g
  return (dirtyUrlSlug || '').replace(regex, '')
}
