import { FetchResult, MutationFunctionOptions, MutationResult, useMutation } from '@apollo/client'
import { useCallback } from 'react'

import {
  CartFragment,
  MergeCartsDocument,
  MergeCartsMutation,
  MergeCartsMutationVariables,
} from '../../../graphql/magento'

export type UseCartMerge = [
  mergeCarts: (
    options: MutationFunctionOptions<MergeCartsMutation, MergeCartsMutationVariables>,
  ) => Promise<MergeCartsFetchResult>,
  result: MergeCartsMutationResult,
]

export type MergeCartsFetchResult = Omit<FetchResult<MergeCartsMutation>, 'data'> & {
  cart?: CartFragment
}

export type MergeCartsMutationResult = Omit<MutationResult<MergeCartsMutation>, 'data'> & {
  cart?: CartFragment
}

export const useCartMerge = (): UseCartMerge => {
  const [mergeCartsMutation, { data, ...rest }] = useMutation(MergeCartsDocument)

  const mergeCarts = useCallback<UseCartMerge['0']>(
    async (options) => {
      const { data: mergeResultData, ...mergeResultRest } = await mergeCartsMutation(options)
      return { cart: mergeResultData?.mergeCarts, ...mergeResultRest }
    },
    [mergeCartsMutation],
  )

  return [mergeCarts, { cart: data?.mergeCarts, ...rest }]
}
