import { toByteArray } from 'base64-js'
import * as R from 'ramda'

import { GetEncodedDispensaryPromosQuery } from '../../../../graphql/camel'
import { hashCode } from './hashCode'
import { InputCodeNotTranslated, PromoCodeUtilities, TranslatedToRootPromo } from './types'
import {
  checkCryptoApiAvailable,
  decodeUtf8ArrayBuffer,
  encodeUtf8ByteArray,
  identityPromise,
  sanitizeInput,
} from './util'

const deriveKeyAndIVWithSizes =
  ({ keySize = 256, ivSize = 128 }: { keySize?: number; ivSize?: number }) =>
  (pass: Uint8Array, salt: Uint8Array) =>
    checkCryptoApiAvailable()
      .then(() =>
        window.crypto.subtle.importKey('raw', pass, { name: 'PBKDF2' }, false, [
          'deriveBits',
          'deriveKey',
        ]),
      )
      .then((passKey) =>
        window.crypto.subtle.deriveBits(
          {
            name: 'PBKDF2',
            salt: salt,
            iterations: 10,
            hash: 'SHA-256',
          },
          passKey,
          keySize + ivSize,
        ),
      )
      .then((derived) => [derived.slice(0, keySize / 8), derived.slice(keySize / 8)])
      .then(async ([key, iv]) => ({
        key: await window.crypto.subtle.importKey('raw', key, { name: 'AES-CBC' }, false, [
          'decrypt',
        ]),
        iv,
      }))

const deriveKeyAndIV = deriveKeyAndIVWithSizes({ keySize: 256, ivSize: 128 })

const startsWithSaltedPrefix = (bytes: Uint8Array): boolean =>
  bytes[0] === 0x53 &&
  bytes[1] === 0x61 &&
  bytes[2] === 0x6c &&
  bytes[3] === 0x74 &&
  bytes[4] === 0x65 &&
  bytes[5] === 0x64 &&
  bytes[6] === 0x5f &&
  bytes[7] === 0x5f

const decodeBase64Secret = (secret: string) => {
  const decoded = toByteArray(secret)
  if (!startsWithSaltedPrefix(decoded)) {
    throw new Error(`Expected secret with 'Salted__' prefix`)
  }
  const salt = decoded.slice(8, 16)
  const cipherText = decoded.slice(16)
  return { cipherText, salt }
}

export const decryptSecret = async ({
  secret,
  pass,
}: {
  secret: string
  pass: string
}): Promise<string> => {
  const { cipherText, salt } = decodeBase64Secret(secret)
  const { key, iv } = await deriveKeyAndIV(encodeUtf8ByteArray(pass), salt)
  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    key,
    cipherText,
  )
  return decodeUtf8ArrayBuffer(decrypted)
}

const findDispensaryPromoMatchingCode = (
  promos: NonNullable<GetEncodedDispensaryPromosQuery['getEncodedDispensaryPromos']>['promos'],
) =>
  R.pipe(
    hashCode,
    R.andThen((inputCodeHash) =>
      promos.find((promo) => promo?.dispensaryCodeHash === inputCodeHash),
    ),
  )

export const tryTranslateCodeForPromos =
  (
    promos: NonNullable<GetEncodedDispensaryPromosQuery['getEncodedDispensaryPromos']>['promos'],
  ): ((inputCode: string) => Promise<string | undefined>) =>
  async (inputCode) => {
    const matchedPromo = await findDispensaryPromoMatchingCode(promos)(inputCode)
    const secret = matchedPromo?.rootCodeSecret
    // root promo code is encrypted with key derived from dispensary promo code
    return secret ? decryptSecret({ secret, pass: inputCode }) : undefined
  }

const translatedToRootPromo = ({
  ...props
}: Omit<TranslatedToRootPromo, '_tag'>): TranslatedToRootPromo => ({ _tag: 'translated', ...props })

const inputCodeNotTranslated = ({
  ...props
}: Omit<InputCodeNotTranslated, '_tag'>): InputCodeNotTranslated => ({
  _tag: 'not_translated',
  ...props,
})

export const translateCodeForGlobal: PromoCodeUtilities['translateCode'] = R.pipe(
  identityPromise,
  R.andThen((inputCode) => inputCodeNotTranslated({ inputCode })),
)

export const translateCodeForDispensary = (
  promos: NonNullable<GetEncodedDispensaryPromosQuery['getEncodedDispensaryPromos']>['promos'],
): PromoCodeUtilities['translateCode'] =>
  R.pipe(
    sanitizeInput,
    R.chain(
      (translatedCodePromise: Promise<string | undefined>) => (inputCode: string) =>
        translatedCodePromise.then((translatedCode) =>
          translatedCode
            ? translatedToRootPromo({ inputCode, translatedCode })
            : inputCodeNotTranslated({ inputCode }),
        ),
      tryTranslateCodeForPromos(promos),
    ),
  )
