import { createTheme } from '@material-ui/core/styles'

import { extendedTheme, extendedTheme as nonMuiTheme } from '../extended'
import palette from './palette'
import typography from './typography'

const customTheme = createTheme({
  nonMuiTheme,
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: palette.primary.main,
        color: palette.primary.light,
        borderRadius: 4,
      },
      root: {
        height: '50px',
        '&:hover': {
          backgroundColor: palette.background.default,
          textDecoration: 'underline',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: palette.background.default,
          },
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.robotoCondensed': {
          fontFamily: extendedTheme.fontFamilies.robotoCondensed,
          fontStretch: 'condensed',
        },
        // Need to force Gatsby's automatically-added divs to fill the whole parent container:
        'html, body, #___gatsby, #gatsby-focus-wrapper': {
          margin: '0',
          height: '100%',
          width: '100%',
        },
      },
    },
    MuiDialogContentText: {
      root: {
        // Prevent weird 'border' around the body of the Dialog when it is clicked:
        '&:focus': {
          outline: 'unset',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: palette.primary.main,
        '& h2': {
          color: palette.primary.light,
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: extendedTheme.colors.coolGray.cool150,
        border: '1px solid transparent',
        '&:hover': {
          backgroundColor: extendedTheme.colors.coolGray.cool150,
          '@media (hover: none)': {
            backgroundColor: extendedTheme.colors.coolGray.cool150,
          },
        },
        '&$disabled': {
          backgroundColor: extendedTheme.colors.coolGray.cool200,
        },
        '&$focused': {
          backgroundColor: extendedTheme.colors.coolGray.cool150,
          borderRadius: 4,
          borderColor: palette.primary.main,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '10px',
      },
    },
    MuiFormLabel: {
      root: {
        color: extendedTheme.colors.coolGray.cool700,
      },
    },
    MuiFormHelperText: {
      root: {
        '&&': {
          fontFamily: extendedTheme.fontFamilies.roboto,
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '0.875rem',
      },
      root: {
        borderRadius: 4,
      },
    },
    MuiInputAdornment: {
      root: {
        fontWeight: 'bold',
      },
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
      root: {
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiLink: {
      // Override any links that would normally only be underlined on hover
      // to always be underlined:
      underlineHover: {
        textDecoration: 'underline',
      },
    },
    MuiOutlinedInput: {
      // Empty objects for disabled and focused are required to allow MUI's theme-creation
      // code to handle the references to `&$disabled` and `&$focused` below.
      disabled: {},
      focused: {},
      notchedOutline: {
        borderColor: extendedTheme.colors.coolGray.cool250,
      },
      root: {
        borderWidth: '1px',
        '&:hover $notchedOutline': {
          borderColor: extendedTheme.colors.coolGray.cool250,
        },
        // Ensure the border matches the grayed-out text within the input:
        '&$disabled $notchedOutline': {
          borderColor: palette.action.disabled,
        },
        // Prevent the border from ever becoming thicker on hover or focus:
        '&$focused $notchedOutline': {
          borderColor: extendedTheme.colors.coolGray.cool250,
          borderWidth: '1px',
        },
        // Override the weird rules MUI puts in place for devices that don't have hover states:
        '@media(hover: none)': {
          '&:hover $notchedOutline': {
            borderColor: extendedTheme.colors.coolGray.cool250,
          },
          '&$disabled $notchedOutline': {
            borderColor: palette.action.disabled,
          },
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          // override default background on focus
          backgroundColor: 'unset',
        },
      },
    },
    MuiToolbar: {
      root: {
        height: '100%',
      },
    },
    MuiTooltip: {
      tooltip: {
        // Override the `fade` applied to the `backgroundColor` in the `Tooltip`
        // component. More info in the source code here:
        //  https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/Tooltip/Tooltip.js#L91
        backgroundColor: extendedTheme?.colors?.brand?.blue || '#0A2240',
        fontSize: '0.75rem',
        borderRadius: '8px',
      },
      arrow: {
        color: extendedTheme?.colors?.brand?.blue || '#0A2240',
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: '0',
        paddingTop: '0',
      },
    },
  },
  palette,
  props: {
    MuiButton: {
      disableElevation: true, // Remove all boxShadows from every button.
      disableFocusRipple: true,
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  spacing: 2,
  typography,
})

export default customTheme
