import { RefObject, useEffect, useRef } from 'react'

/**
 * Cleanup hook to track whether component is mounted
 */
const useIsMounted = (): RefObject<boolean> => {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export default useIsMounted
