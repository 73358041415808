import { ApolloError } from '@apollo/client'
import * as R from 'ramda'
import { useCallback } from 'react'

import { SimpleProductCartItemInput } from '../../../../graphql/magento'
import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { constVoid } from '../../../../utils/constant'
import type { Maybe } from '../../../../utils/types'
import { publishAddToCartEventForItems } from '../../../analytics/cart'
import { UseCartAddSimpleProducts, useCartAddSimpleProducts } from '../../../cart'
import { useAddToCartToast } from '../../../cart/hooks/useAddToCartToast'
import { EScriptParamsLoader, EScriptUpdateCartAction } from '../../../escript/types'
import { isAddToCartAction } from '../../../escript/utils'
import { useTestKitProducts } from '../../../products/hooks/useTestKitProducts'
import { setDispensaryCartState } from '../../events/cart'

export type UseAddProductsToCart = [
  addProductsToCart: () => Promise<void>,
  result: UseCartAddSimpleProducts['1'],
]

export interface UseAddProductsToCartOptions {
  eScriptParamsLoader: EScriptParamsLoader
}

const shouldAddToCart = (
  updateCartActions: Maybe<EScriptUpdateCartAction[]>,
): updateCartActions is EScriptUpdateCartAction[] => {
  return Boolean(
    updateCartActions && updateCartActions.some((action) => action?.operation === 'add'),
  )
}

const getProducts = (updateCartActions: EScriptUpdateCartAction[]) => {
  return updateCartActions
    .filter(isAddToCartAction)
    .map(({ products }) => products)
    .flat()
}

interface AddToCartResult {
  itemsAdded: SimpleProductCartItemInput[]
  itemsFailed: SimpleProductCartItemInput[]
}

const extractFailedSku = (message: string): string | undefined => {
  const regex1 = /Could not find a product with SKU "(.*)"/
  const regex2 = /Could not add the product with SKU ([^(*)]+) to the shopping cart/
  return regex1.exec(message)?.[1] ?? regex2.exec(message)?.[1] ?? undefined
}

const reduceAddToCartResult = (skuFailed: string) =>
  R.groupBy<SimpleProductCartItemInput, 'itemsFailed' | 'retryItems'>((item) =>
    item.data.sku === skuFailed ? 'itemsFailed' : 'retryItems',
  )

const publishAddToCartEvent =
  (affiliateId: string | undefined) =>
  ({ itemsAdded }: AddToCartResult) => {
    if (itemsAdded.length > 0) {
      publishAddToCartEventForItems({
        affiliation: affiliateId,
        items: itemsAdded.map(
          ({ data: { quantity, sku, dfh_autoship_interval: autoShipInterval } }) => ({
            quantity,
            sku,
            ...(autoShipInterval && { dfh_autoship_interval: autoShipInterval }),
          }),
        ),
        appContext: 'escript',
      })
    }
  }

export const useAddProductsToCart = ({
  eScriptParamsLoader,
}: UseAddProductsToCartOptions): UseAddProductsToCart => {
  const { loadParams } = eScriptParamsLoader

  const { isTestKitSku } = useTestKitProducts()
  const [addSimpleProductsToCart, result] = useCartAddSimpleProducts()
  const { error } = result

  const { showFailureToast } = useAddToCartToast()

  useLogAndCaptureError(error)

  const showFailedItemsToast = useCallback(
    (addResult: AddToCartResult) => {
      if (addResult.itemsFailed.length > 0) {
        showFailureToast({
          skus: addResult.itemsFailed.map((itemFailed) => itemFailed.data.sku),
          additionalMessage: `Either the prescription link was incorrect or the product is currently unavailable. Please double check your order and contact your practitioner about any concerns.`,
        })
      }
    },
    [showFailureToast],
  )

  const tryAddProductsToCart = useCallback(
    (
      addItems: SimpleProductCartItemInput[],
      itemsFailedAcc: SimpleProductCartItemInput[],
    ): Promise<AddToCartResult> =>
      addSimpleProductsToCart({
        variables: {
          cartItems: addItems,
        },
      })
        .then(() => ({ itemsAdded: addItems, itemsFailed: itemsFailedAcc }))
        .catch((addError: unknown) => {
          if (!(addError instanceof ApolloError) || addError.networkError) {
            return Promise.reject(addError)
          }
          const failedSku = extractFailedSku(addError.message)
          if (!failedSku) {
            return Promise.reject(addError)
          }
          const { retryItems, itemsFailed } = reduceAddToCartResult(failedSku)(addItems)
          const itemsFailedAccNew = [...itemsFailedAcc, ...itemsFailed]
          if (!retryItems || retryItems.length === 0) {
            return { itemsAdded: [], itemsFailed: itemsFailedAccNew }
          }
          return tryAddProductsToCart(retryItems, itemsFailedAccNew)
        }),
    [addSimpleProductsToCart],
  )

  const addProductsToCart = useCallback(async () => {
    const { updateCartActions, affiliateId } = await loadParams()

    if (!shouldAddToCart(updateCartActions)) {
      return
    }
    const products = getProducts(updateCartActions)
    if (!products) {
      return
    }
    const items = products.map((product) => ({
      data: {
        sku: product.productSku,
        quantity: product.productQuantity,
        ...(product?.productAutoShip &&
          !isTestKitSku(product.productSku) && {
            dfh_order_type: 2,
            dfh_autoship_interval: autoShipIntervalToAutoShipOption(product.productAutoShip),
          }),
      },
    }))

    const productsForState = products.map(({ productSku, productQuantity, productAutoShip }) => ({
      sku: productSku,
      quantity: productQuantity,
      ...(productAutoShip &&
        !isTestKitSku(productSku) && {
          dfh_autoship_interval: autoShipIntervalToAutoShipOption(productAutoShip),
        }),
    }))
    setDispensaryCartState({
      type: 'cart_add_products_start',
      products: productsForState,
    })
    return tryAddProductsToCart(items, [])
      .then(R.tap(showFailedItemsToast))
      .then(R.tap(publishAddToCartEvent(affiliateId)))
      .then(
        R.tap(() =>
          setDispensaryCartState({
            type: 'cart_add_products_complete',
            products: productsForState,
          }),
        ),
      )
      .then(constVoid)
  }, [isTestKitSku, loadParams, showFailedItemsToast, tryAddProductsToCart])

  return [addProductsToCart, result]
}

function autoShipIntervalToAutoShipOption(autoShipInterval: string): number {
  return parseInt(autoShipInterval, 10)
}
