import { ApolloError } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'

import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { isCartNotFoundError } from '../utils'
import { CartDataForUpdate, CartUpdateAttributesVariables } from './useCartUpdateAfterMerge'
import { useGuestCartGetById } from './useGuestCartGetById'

export type UseGuestCartDataForUpdateAfterMerge = {
  cartDataForUpdate: CartDataForUpdate | undefined
  error?: ApolloError | Error
  loading: boolean
}

export type UseGuestCartDataForUpdateAfterMergeOptions = {
  guestCartId: string | undefined
}

export const useGuestCartDataToUpdateAfterMerge = ({
  guestCartId,
}: UseGuestCartDataForUpdateAfterMergeOptions): UseGuestCartDataForUpdateAfterMerge => {
  const [loading, setLoading] = useState(true)
  const [cartAttributes, setCartAttributes] = useState<CartUpdateAttributesVariables | undefined>(
    undefined,
  )

  const [getGuestCart, { cart: guestCart, loading: loadingGuestCart, error: errorGuestCart }] =
    useGuestCartGetById()

  const isGuestCartNotFound = isCartNotFoundError(errorGuestCart, guestCartId)
  const error = isGuestCartNotFound ? undefined : errorGuestCart

  useLogAndCaptureError(error)

  const cartDataForUpdate = useMemo<CartDataForUpdate | undefined>(() => {
    if (!cartAttributes) {
      return undefined
    }
    return { cartAttributes }
  }, [cartAttributes])

  useEffect(() => {
    if (isGuestCartNotFound) {
      setLoading(false)
      return
    }
    if (!guestCartId || cartAttributes) {
      return
    }
    if (loadingGuestCart) {
      return
    }
    if (errorGuestCart) {
      setLoading(false)
      return
    }
    if (!guestCart) {
      getGuestCart(guestCartId)
      return
    }
    const guestCartAttributes: CartUpdateAttributesVariables = {
      practitionerCode: guestCart.practitioner_code || '',
      wellWorldId: guestCart.ww_id || '',
      wellWorldStoreId: guestCart.ww_store_id || '',
      wellWorldPracticeId: guestCart.ww_practice_id || '',
      wellWorldPractitionerId: guestCart.ww_practitioner_id || '',
    }
    setCartAttributes(guestCartAttributes)
    setLoading(false)
  }, [
    cartAttributes,
    guestCartId,
    loadingGuestCart,
    errorGuestCart,
    isGuestCartNotFound,
    getGuestCart,
    guestCart,
  ])

  return { cartDataForUpdate, error: errorGuestCart, loading }
}
