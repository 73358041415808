import {
  UseSiteSettingsAndMetadata,
  useSiteSettingsAndMetadata,
} from './useSiteSettingsAndMetadata'

export type UseSiteMetadata = NonNullable<UseSiteSettingsAndMetadata['site']>['siteMetadata']

export const useSiteMetadataFrom = ({
  site,
}: Pick<UseSiteSettingsAndMetadata, 'site'>): UseSiteMetadata => {
  return site?.siteMetadata
}

const useSiteMetadata = (): UseSiteMetadata => useSiteMetadataFrom(useSiteSettingsAndMetadata())

export default useSiteMetadata
