import type { ApolloError } from '@apollo/client'
import { createContext } from 'react'

import type { CartFragment } from '../../graphql/magento'
import { MagentoScopeNotGrantedError } from '../auth/errors'

export interface CartContextValue {
  cart?: CartFragment
  loading: boolean
  error?: ApolloError | Error | MagentoScopeNotGrantedError
  setAllowGuestCart: (allowGuestCart: boolean) => void
  refetch?: () => void
}

export type NonEmptyCartContextValue = CartFragment

export interface ToggleCartContextValue {
  isCartOpen: boolean
  toggleCart(): void
  hideCart(): void
  showCart(): void
}

export const CartContext = createContext<CartContextValue>({
  cart: undefined,
  error: undefined,
  loading: false,
  setAllowGuestCart: () => {
    // empty initializer
  },
  refetch: () => {
    // empty initializer
  },
})

export const NonEmptyCartContext = createContext<NonEmptyCartContextValue>({
  id: '-1',
  shipping_addresses: [],
  total_quantity: 0,
})

export const ToggleCartContext = createContext<ToggleCartContextValue>({
  isCartOpen: false,
  toggleCart() {
    console.warn('Toggle cart context not initialized')
  },
  hideCart() {
    console.warn('Hide cart context not initialized')
  },
  showCart() {
    console.warn('Show cart context not initialized')
  },
})
