import { CustomError } from '../../utils/errorTools'

export class LabelNameNotFoundError extends CustomError {
  name = 'LabelNameNotFoundError'
}

/**
 * Indicates some operation cannot proceed due to access token missing `magento_user` scope
 *
 * Note: not extending CustomError to avoid continuing the headache with babel and Error
 * class inheritance.
 */
export class MagentoScopeNotGrantedError {
  name = 'MagentoScopeNotGrantedError'

  get [Symbol.toStringTag](): string {
    return this.name
  }
}

export function isMagentoScopeNotGrantedError(err: unknown): err is MagentoScopeNotGrantedError {
  return err instanceof MagentoScopeNotGrantedError
}
