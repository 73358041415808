import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { AuthState as AuthStateSdk } from '@auth0/auth0-react/dist/auth-state'
import { User } from '@auth0/auth0-spa-js'
import { bind, shareLatest, state } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
} from 'rxjs'
import { filter } from 'rxjs/operators'

import { isNotNull } from '../../utils/collectionTools'
import { logAndCaptureException } from '../../utils/errorTools'
import { isUserCategory, UserCategory, userClaimIds } from '../../utils/userClaims'
import { LabelNameNotFoundError } from './errors'

export type AuthState = Pick<AuthStateSdk, 'isAuthenticated' | 'isLoading' | 'user'>
export type AuthStateWithAccessToken =
  | { isAuthenticated: true; user: User; accessToken: string }
  | { isAuthenticated: false }

const [authState$, setAuthState] = createSignal<AuthState>()

const distinctAuthState$ = authState$.pipe(
  distinctUntilChanged(
    (previous, current) =>
      previous.isAuthenticated === current.isAuthenticated &&
      previous.isLoading === current.isLoading &&
      previous.user === current.user,
  ),
  shareLatest(),
)

export interface CachedUserTransform {
  user: NonNullable<AuthState['user']>
  project: (user: NonNullable<AuthState['user']>) => NonNullable<AuthState['user']>
}

// transforms associated with specific user objects to allow modifying cached user without
// invalidating access tokens (useful after metadata updates)
const cachedUserTransforms$ = new Subject<CachedUserTransform[]>()

const latestCachedUserTransforms$ = state(cachedUserTransforms$, [])

const [accessToken$, setAccessToken] = createSignal<string | undefined>()

const distinctAccessToken$ = accessToken$.pipe(distinctUntilChanged())

const [accessTokenFactory$, setAccessTokenFactory] = createSignal<
  (
    options?: Pick<GetTokenSilentlyOptions, 'audience' | 'scope' | 'ignoreCache'> & {
      updateTokenContext?: boolean
    },
  ) => Observable<string>
>()

export { setAccessToken, setAccessTokenFactory, setAuthState }

/**
 * Find transforms where user is referentially equal to input parameter
 */
const transformsForCachedUser: (
  user: NonNullable<AuthState['user']>,
) => Observable<Array<CachedUserTransform['project']>> = (user) =>
  latestCachedUserTransforms$.pipe(
    map((transforms) =>
      transforms.reduce((acc: Array<CachedUserTransform['project']>, transform) => {
        if (transform.user === user) {
          acc.push(transform.project)
        }
        return acc
      }, []),
    ),
  )

export const [useAuthState, latestAuthState$] = bind(
  distinctAuthState$.pipe(
    switchMap((authState) => {
      const { user } = authState
      return user
        ? transformsForCachedUser(user).pipe(
            // apply transforms (if any) to cached user object that allow updating local state
            // without invalidating id and access tokens via `getAccessTokenSilently`
            map((transforms) =>
              transforms.length
                ? {
                    ...authState,
                    user: transforms.reduce((acc, transform) => transform(acc), user),
                  }
                : // return original authState if no transforms found
                  authState,
            ),
          )
        : of(authState)
    }),
  ),
  {
    isAuthenticated: false,
    isLoading: false,
    user: undefined,
  },
)

export const latestAccessToken$ = state(distinctAccessToken$, undefined)

export const latestAccessTokenFactory$ = accessTokenFactory$.pipe(shareLatest())

/**
 * Combine auth state with access token, emitting only when authentication settled and either
 * user is not authenticated or both user and access token are available.
 */
const authStateWithAccessToken$ = combineLatest([distinctAuthState$, distinctAccessToken$]).pipe(
  map(([authState, accessToken]) => ({ ...authState, accessToken })),
  filter(
    (
      combinedAuthState: AuthState & { accessToken?: string | undefined },
    ): combinedAuthState is AuthStateWithAccessToken & { isLoading: false } => {
      const { isLoading, isAuthenticated, user, accessToken } = combinedAuthState
      return !isLoading && (!isAuthenticated || (!!user && !!accessToken))
    },
  ),
  map(
    ({ isLoading, ...authStateWithAccessToken }): AuthStateWithAccessToken =>
      authStateWithAccessToken,
  ),
)

export const latestAuthStateWithAccessToken$ = state(authStateWithAccessToken$)

/**
 * Extract the user category from claims in the latest user token.
 *
 * Returns `undefined` if claim not found, not valid, or user not authenticated.
 */
export const latestUserCategory$: Observable<UserCategory | undefined> = latestAuthState$.pipe(
  map(({ user }) => (user ? (user[userClaimIds.category] as unknown) : undefined)),
  map((userCategory) => (isUserCategory(userCategory) ? userCategory : undefined)),
)

/**
 * Label name derived from user token. Use this with GraphQL requests for label status, etc.
 *
 * Error is captured before sharing result - no need to capture again.
 */
export const latestLabelName$: Observable<
  { _tag: 'Left'; error: LabelNameNotFoundError } | { _tag: 'Right'; data: string }
> = state(
  latestAuthState$.pipe(
    map(({ user }) => user),
    filter(isNotNull),
    map((user) => {
      const labelName = user[userClaimIds.companyName]
      if (!labelName || typeof labelName !== 'string') {
        const error = new LabelNameNotFoundError()
        // capture exception once before sharing/replaying result
        logAndCaptureException(error)
        return { _tag: 'Left' as const, error }
      }
      return { _tag: 'Right' as const, data: labelName }
    }),
  ),
)

/**
 * Add transform to update user in local Auth0 state without invalidating access tokens
 * (i.e. using `getAccessTokenSilently()` to fetch new tokens from Auth0)
 */
export const transformUserInAuthState = (
  project: (user: NonNullable<AuthState['user']>) => NonNullable<AuthState['user']>,
): Observable<void> =>
  distinctAuthState$.pipe(
    take(1),
    switchMap(
      ({ user }): Observable<CachedUserTransform[]> =>
        user
          ? // create new set of transforms for current Auth0 user object
            latestCachedUserTransforms$.pipe(
              // one and done
              take(1),
              map((transforms) => [
                // only retain transforms for the current user object
                ...transforms.filter((transform) => transform.user === user),
                // add new transform
                {
                  user,
                  project,
                },
              ]),
            )
          : EMPTY,
    ),
    map((transforms) => {
      cachedUserTransforms$.next(transforms)
    }),
  )
