import { state } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'

import { Dispensary } from './types'

const [dispensaryChange$, setDispensary] = createSignal<Dispensary | undefined>()

export { setDispensary }

export const latestDispensary$ = state(dispensaryChange$, null)
