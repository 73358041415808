import { useMemo } from 'react'

import { GlobalSettings, useGlobalSettings } from './useGlobalSettings'

export type UseSiteSettingsAndMetadata = Pick<GlobalSettings, 'sanitySiteSettings' | 'site'>

export const useSiteSettingsAndMetadata = (): UseSiteSettingsAndMetadata => {
  const { sanitySiteSettings, site } = useGlobalSettings()
  return useMemo(() => ({ sanitySiteSettings, site }), [sanitySiteSettings, site])
}
