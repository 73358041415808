import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { combineLatest, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs'

import { logAndCaptureException } from '../../../utils/errorTools'

export interface DispensaryCartInitial {
  type: 'cart_initial'
}

export interface DispensaryCartEmptyStart {
  type: 'cart_empty_start'
}

export interface DispensaryCartEmptyComplete {
  type: 'cart_empty_complete'
}

export interface DispensaryCartAddProductsStart {
  type: 'cart_add_products_start'
  products: {
    sku: string
    quantity: number
  }[]
}

export interface DispensaryCartAddProductsComplete {
  type: 'cart_add_products_complete'
  products: {
    sku: string
    quantity: number
  }[]
}

export interface DispensaryCartRemovePromoCodeStart {
  type: 'cart_remove_promo_code_start'
  promoCodes: string[]
}

export interface DispensaryCartRemovePromoCodeComplete {
  type: 'cart_remove_promo_code_complete'
  promoCodes: string[]
}

export interface DispensaryCartApplyPromoCodeStart {
  type: 'cart_apply_promo_code_start'
  promoCode: string
}

export interface DispensaryCartApplyPromoCodeComplete {
  type: 'cart_apply_promo_code_complete'
  promoCode: string
}

export interface DispensaryCartSetDispensaryPromoCodeStart {
  type: 'cart_set_dispensary_promo_code_start'
  dispensaryPromoCode: string
}

export interface DispensaryCartSetDispensaryPromoCodeComplete {
  type: 'cart_set_dispensary_promo_code_complete'
  dispensaryPromoCode: string
}

export interface DispensaryCartUpdateAttributesStart {
  type: 'cart_update_attributes_start'
  practitionerCode?: string | null
  wellWorldId?: string | null
  wellWorldStoreId?: string | null
  wellWorldPracticeId?: string | null
}

export interface DispensaryCartUpdateAttributesComplete {
  type: 'cart_update_attributes_complete'
  practitionerCode?: string | null
  wellWorldId?: string | null
  wellWorldStoreId?: string | null
  wellWorldPracticeId?: string | null
}

export interface DispensaryCartFinal {
  type: 'cart_final'
}

export interface DispensaryCartError {
  type: 'cart_error'
  error: Error
}

export type DispensaryCartState =
  | DispensaryCartInitial
  | DispensaryCartEmptyStart
  | DispensaryCartEmptyComplete
  | DispensaryCartAddProductsStart
  | DispensaryCartAddProductsComplete
  | DispensaryCartRemovePromoCodeStart
  | DispensaryCartRemovePromoCodeComplete
  | DispensaryCartApplyPromoCodeStart
  | DispensaryCartApplyPromoCodeComplete
  | DispensaryCartSetDispensaryPromoCodeStart
  | DispensaryCartSetDispensaryPromoCodeComplete
  | DispensaryCartUpdateAttributesStart
  | DispensaryCartUpdateAttributesComplete
  | DispensaryCartFinal
  | DispensaryCartError

const initialState: DispensaryCartState = { type: 'cart_initial' }

export const [dispensaryCartStateChange$, setDispensaryCartState] =
  createSignal<DispensaryCartState>()

export const dispensaryCartState$ = dispensaryCartStateChange$.pipe(
  startWith<DispensaryCartState>({ ...initialState }),
)

const dispensaryCartStart$ = dispensaryCartState$.pipe(
  filter(
    (
      event,
    ): event is
      | DispensaryCartInitial
      | DispensaryCartEmptyStart
      | DispensaryCartAddProductsStart
      | DispensaryCartRemovePromoCodeStart
      | DispensaryCartApplyPromoCodeStart
      | DispensaryCartSetDispensaryPromoCodeStart
      | DispensaryCartUpdateAttributesStart
      | DispensaryCartFinal => {
      return (
        event.type === 'cart_initial' ||
        event.type.endsWith('_start') ||
        event.type === 'cart_final'
      )
    },
  ),
  distinctUntilChanged(),
)

const dispensaryCartComplete$ = dispensaryCartState$.pipe(
  filter(
    (
      event,
    ): event is
      | DispensaryCartInitial
      | DispensaryCartEmptyComplete
      | DispensaryCartAddProductsComplete
      | DispensaryCartRemovePromoCodeComplete
      | DispensaryCartApplyPromoCodeComplete
      | DispensaryCartSetDispensaryPromoCodeComplete
      | DispensaryCartUpdateAttributesComplete
      | DispensaryCartFinal => {
      return (
        event.type === 'cart_initial' ||
        event.type.endsWith('_complete') ||
        event.type === 'cart_final'
      )
    },
  ),
  distinctUntilChanged(),
)

const dispensaryCartLoading$ = dispensaryCartState$.pipe(
  map((event): boolean => {
    return event.type !== 'cart_initial' && event.type !== 'cart_final'
  }),
  distinctUntilChanged(),
)

const dispensaryCartCalled$ = dispensaryCartState$.pipe(
  map((event): boolean => {
    return event.type !== 'cart_initial'
  }),
  distinctUntilChanged(),
)

const dispensaryCartError$ = dispensaryCartState$.pipe(
  filter((event): event is DispensaryCartError => {
    return event.type === 'cart_error'
  }),
  map(({ error }): Error => error),
  tap((error) => logAndCaptureException(error)),
)

const dispensaryCartStatusChange$ = combineLatest([
  dispensaryCartStart$,
  dispensaryCartComplete$,
  dispensaryCartLoading$,
  dispensaryCartCalled$,
  dispensaryCartError$.pipe(startWith(undefined)),
]).pipe(
  map(([startState, completeState, loading, called, error]) => ({
    startState,
    completeState,
    loading,
    called,
    error,
  })),
)

export const [useDispensaryCartStatus, dispensaryCartStatus$] = bind(dispensaryCartStatusChange$, {
  startState: { ...initialState },
  completeState: { ...initialState },
  loading: false,
  called: false,
  error: undefined,
})
