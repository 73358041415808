import { extendedTheme } from '../extended'
// import { PaletteOptions } from '@material-ui/core/styles/createPalette'

const palette = {
  action: {
    // Value here matches the default value in the MUI palette. Needed here
    // so it can be referenced in the theme customization below.
    disabled: 'rgba(0, 0, 0, 0.26)',
  },
  background: {
    default: '#ffffff',
  },
  error: {
    main: extendedTheme.colors.semantic.error,
  },
  primary: {
    light: '#ffffff',
    main: extendedTheme.colors.component.backgroundDarkBlue,
  },
  secondary: {
    light: '#ffffff',
    main: extendedTheme.colors.component.backgroundDarkBlue,
  },
  text: {
    primary: extendedTheme.colors.grayscale.black,
  },
} as const

export default palette
