import { useEffect } from 'react'

import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import type { UseAuth } from '../../../auth'
import { areCartsEqual } from '../../utils'
import { actions, UseCartGetReducer } from '../useCartGetReducer'
import { UseGuestCartGet, useGuestCartGet } from '../useGuestCartGet'

export const useGuestCartActions = ({
  reducer,
  authState,
}: {
  reducer: UseCartGetReducer
  authState: UseAuth
}): UseGuestCartGet => {
  const [state, dispatch] = reducer
  const { cart, type, loading, error, guestCartId, guestStatus, mergeStatus, refetchRequested } =
    state

  const [getGuestCart, guestCartState, { clearCartId: clearGuestCartId }] = useGuestCartGet()

  useLogAndCaptureError(guestCartState.error)

  // dispatch updating guest cart id in cart actions
  useEffect(() => {
    if (mergeStatus === 'complete') {
      return
    }
    if (guestCartState.loading) {
      return
    }
    if ((guestCartId || undefined) !== (guestCartState.cartId || undefined)) {
      dispatch(actions.updateGuestCartId(guestCartState.cartId || undefined))
    }
  }, [dispatch, guestCartId, guestCartState.loading, guestCartState.cartId, mergeStatus])

  // dispatch guest cart actions
  useEffect(() => {
    if (authState.isLoading) {
      return
    }
    if (error || type !== 'guest') {
      return
    }
    if (guestCartState.loading) {
      return
    }
    if (guestCartState.error && !refetchRequested) {
      dispatch(actions.guestLoadError(guestCartState.error))
      return
    }
    if (areCartsEqual(cart, guestCartState.cart) && !refetchRequested) {
      return
    }
    if (guestCartState.cart && !refetchRequested) {
      if (guestStatus === 'complete' || guestStatus === 'error') {
        dispatch(actions.cartUpdate(guestCartState.cart))
        return
      }
      dispatch(actions.guestLoadComplete(guestCartState.cart))
      return
    }
    if (guestCartState.called && !refetchRequested) {
      return
    }
    dispatch(actions.guestLoadRequest())
    getGuestCart(refetchRequested)
  }, [
    cart,
    dispatch,
    error,
    type,
    guestStatus,
    loading,
    authState,
    guestCartState,
    getGuestCart,
    refetchRequested,
  ])

  return [getGuestCart, guestCartState, { clearCartId: clearGuestCartId }]
}
