import { ApolloError, FetchResult } from '@apollo/client'
import { GraphQLError } from 'graphql'

import { left, Result, right } from './result'

/**
 * Check Apollo query response for errors and throw ApolloError if found. This
 * facilitates chaining requests using Apollo's Promise API.
 */
export function checkQueryResponse<TData>({
  data,
  errors,
}: FetchResult<TData>): TData | undefined | null {
  if (errors && errors.length > 0) {
    throw new ApolloError({ graphQLErrors: errors })
  }
  return data
}

/**
 * Check GraphQL query response for errors and return Left or Right result accordingly
 */
export const parseQueryResult = <TData>({
  data,
  errors,
}: FetchResult<TData>): Result<TData | undefined | null, ReadonlyArray<GraphQLError>> =>
  errors && errors.length > 0 ? left(errors) : right(data)

/**
 * Check Apollo query response for errors and throw ApolloError if found. Successful
 * response is discarded. This facilitates chaining requests using Apollo's Promise API.
 */
export function checkAndDiscardQueryResponse({ errors }: FetchResult): void {
  if (errors && errors.length > 0) {
    throw new ApolloError({ graphQLErrors: errors })
  }
}

export const tapSuccessfulQueryResponse =
  <TData>(onSuccess: (data: TData | undefined | null) => void | PromiseLike<void>) =>
  (response: FetchResult<TData>): Promise<FetchResult<TData>> => {
    const { data, errors } = response
    if (errors && errors.length > 0) {
      return Promise.resolve(response)
    }
    return Promise.resolve(onSuccess(data)).then(() => response)
  }

export const graphqlErrorsMessage = (errors: ReadonlyArray<GraphQLError>): string =>
  errors.map(({ message }) => message).join('\n')
