import { logAndCaptureMessage } from '../../utils/errorTools'

export interface CartDispensaryPromoCode {
  dispensaryCode: string
  rootCode?: string
}

function isStringArray(value: unknown): value is string[] {
  if (value == null || !Array.isArray(value)) {
    return false
  }
  return value.every((item) => typeof item === 'string')
}

export const readCartDispensaryPromoCodes = (attributeValue: string): CartDispensaryPromoCode[] => {
  if (!attributeValue) {
    return []
  }
  let parsed: string[]
  try {
    parsed = JSON.parse(attributeValue.toUpperCase())
  } catch (err) {
    logAndCaptureMessage(
      `Could not parse dispensary promo code attribute: "${attributeValue}"`,
      'warning',
    )
    return []
  }

  if (!isStringArray(parsed)) {
    logAndCaptureMessage(`Invalid dispensary promo code value: "${attributeValue}"`, 'warning')
    return []
  }

  return parsed.map((attributeItem) => {
    const [dispensaryCode, rootCode] = attributeItem.split(':')
    return { dispensaryCode, rootCode }
  })
}

export const appendCartDispensaryPromoCode = (
  newItem: CartDispensaryPromoCode,
  items: ReadonlyArray<CartDispensaryPromoCode>,
): CartDispensaryPromoCode[] => {
  if (items.find(({ dispensaryCode }) => newItem.dispensaryCode)) {
    return [...items]
  }
  return [...items, newItem]
}

export const writeCartDispensaryPromoCodes = (
  items: ReadonlyArray<CartDispensaryPromoCode>,
): string =>
  JSON.stringify(
    items.map(
      ({ dispensaryCode, rootCode }) => `${dispensaryCode}${rootCode ? `:${rootCode}` : ''}`,
    ),
  )
