export enum SanityContext {
  Webstore = 'WEBSTORE',
  Dispensary = 'DISPENSARY',
}

export const sanityDefaultContext = SanityContext.Webstore

export const sanityContexts = Object.values(SanityContext)

export function isSanityContext(value: string | undefined | null): value is SanityContext {
  return !!value && sanityContexts.includes(value as SanityContext)
}
