import { createContext } from 'react'

export interface NavigationBarOption {
  label: string
  value: number
}

export const TokenContext = createContext<string | undefined>(undefined)

export const HubSpotContext = createContext<{
  trackEvent(event: { id: string; value: number }): void
}>({
  trackEvent() {
    console.warn('HubSpot context not initialized')
  },
})

export const CheckoutNavigationBarOptionsContext = createContext<{
  navigationBarOptions: NavigationBarOption[]
  displayAgreements: boolean
  displayRegistration: boolean
  displayOnboarding: boolean
  loading: boolean
}>({
  navigationBarOptions: [],
  displayAgreements: false,
  displayRegistration: false,
  displayOnboarding: false,
  loading: false,
})
