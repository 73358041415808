import { ApolloError } from '@apollo/client'
import { Dispatch, useReducer } from 'react'

import { CartFragment } from '../../../graphql/magento'
import { MagentoScopeNotGrantedError } from '../../auth/errors'
import {
  CartGetAction,
  cartGetInitialState,
  CartGetState,
  reduceCartGet,
} from '../reducers/cart-get-state'

export type UseCartGetReducer = [
  cartGetState: CartGetState,
  dispatch: Dispatch<CartGetAction>,
  actions: typeof actions,
]

export const actions = {
  loggedIn: (): CartGetAction => ({ type: 'loggedIn' }),
  loggedOut: (): CartGetAction => ({ type: 'loggedOut' }),
  allowGuest: (): CartGetAction => ({ type: 'allowGuest' }),
  disallowGuest: (): CartGetAction => ({ type: 'disallowGuest' }),
  updateGuestCartId: (guestCartId: string | undefined): CartGetAction => ({
    type: 'updateGuestCartId',
    guestCartId,
  }),
  guestLoadRequest: (): CartGetAction => ({ type: 'guestLoadRequest' }),
  guestLoadComplete: (cart: CartFragment): CartGetAction => ({ type: 'guestLoadComplete', cart }),
  guestLoadError: (error: ApolloError | Error): CartGetAction => ({
    type: 'guestLoadError',
    error,
  }),
  customerLoadRequest: (): CartGetAction => ({ type: 'customerLoadRequest' }),
  customerLoadComplete: (cart: CartFragment): CartGetAction => ({
    type: 'customerLoadComplete',
    cart,
  }),
  customerLoadError: (error: ApolloError | Error | MagentoScopeNotGrantedError): CartGetAction => ({
    type: 'customerLoadError',
    error,
  }),
  mergeRequest: (): CartGetAction => ({ type: 'mergeRequest' }),
  mergeComplete: (cart: CartFragment): CartGetAction => ({ type: 'mergeComplete', cart }),
  mergeError: (error: ApolloError | Error): CartGetAction => ({ type: 'mergeError', error }),
  cartUpdate: (cart: CartFragment): CartGetAction => ({ type: 'cartUpdate', cart }),
  refetch: (): CartGetAction => ({ type: 'refetch' }),
} as const

export const useCartGetReducer = (): UseCartGetReducer => {
  const [cartGetState, dispatch] = useReducer(reduceCartGet, cartGetInitialState)
  return [cartGetState, dispatch, actions]
}
