import { Auth0ContextInterface } from '@auth0/auth0-react'
import { setContext } from '@sentry/gatsby'
import { useEffect } from 'react'

import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { isMagentoScopeNotGrantedError } from '../../../auth/errors'
import { areCartsEqual } from '../../utils'
import { actions, UseCartGetReducer } from '../useCartGetReducer'
import { UseCustomerCartGet, useCustomerCartGet } from '../useCustomerCartGet'

const customerCartStateContext = ({
  cart: customerCart,
  error: customerCartError,
  ...customerCartRest
}: UseCustomerCartGet[1]): { [key: string]: unknown } => ({
  ...customerCartRest,
  cartId: customerCart?.id,
  error: customerCartError && {
    name: customerCartError.name,
    message: !isMagentoScopeNotGrantedError(customerCartError)
      ? customerCartError.message
      : undefined,
  },
})

export const useCustomerCartActions = ({
  reducer,
  authState,
}: {
  reducer: UseCartGetReducer
  authState: Auth0ContextInterface
}): UseCustomerCartGet => {
  const [state, dispatch] = reducer
  const { cart, type, error, customerStatus, refetchRequested } = state
  const [getCustomerCart, customerCartState] = useCustomerCartGet()

  const { error: cartStateError } = customerCartState

  // update Sentry context for error reporting
  useEffect(() => {
    setContext('Customer Cart State', customerCartStateContext(customerCartState))
  }, [customerCartState])

  useLogAndCaptureError(!isMagentoScopeNotGrantedError(cartStateError) ? cartStateError : undefined)

  // handle customer cart actions
  useEffect(() => {
    if (authState.isLoading) {
      return
    }
    if (error || type !== 'customer') {
      return
    }
    if (customerCartState.loading) {
      return
    }
    if (cartStateError && !refetchRequested) {
      dispatch(actions.customerLoadError(cartStateError))
      return
    }
    if (areCartsEqual(cart, customerCartState.cart) && !refetchRequested) {
      return
    }
    if (customerCartState.cart && !refetchRequested) {
      if (customerStatus === 'complete' || customerStatus === 'error') {
        dispatch(actions.cartUpdate(customerCartState.cart))
        return
      }
      dispatch(actions.customerLoadComplete(customerCartState.cart))
      return
    }
    if (customerCartState.called && !refetchRequested) {
      return
    }
    dispatch(actions.customerLoadRequest())
    getCustomerCart(refetchRequested)
  }, [
    cart,
    dispatch,
    error,
    type,
    customerStatus,
    authState,
    customerCartState,
    getCustomerCart,
    refetchRequested,
    cartStateError,
  ])

  return [getCustomerCart, customerCartState]
}
