import { EMPTY, Observable, retry } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { CartFragment } from '../../../../graphql/magento'
import { logAndCaptureException } from '../../../../utils/errorTools'
import { latestCustomerCart$ } from '../cart'

/**
 * Unwrap customer cart result, retrying on `Left` and emitting cart items on `Right`.
 */
export const cartItemsWithRetry$: Observable<CartFragment['items']> = latestCustomerCart$.pipe(
  // unwrap and throw error if `Left` so we can use `retry` operator
  map((result) => {
    if (result._tag === 'Left') {
      throw result.error
    }
    return result.data.customerCart.items
  }),
  retry({
    count: 3,
    delay: 5000, // wait 5 seconds
    resetOnSuccess: true,
  }),
  catchError((err: unknown) => {
    logAndCaptureException(new Error(`Cart items should have been returned. ${err}`))
    return EMPTY
  }),
)
