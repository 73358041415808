import React, { FC, useCallback, useEffect, useState } from 'react'

import { ToggleCartContext } from '../lib/cart/context'
import { useLocationContext } from '../lib/pages/hooks/useLocationContext'

export const ToggleCartContextProvider: FC = ({ children }) => {
  const location = useLocationContext()
  const [isCartOpen, setIsCartOpen] = useState(false)

  const showCart = useCallback(() => setIsCartOpen(true), [])
  const hideCart = useCallback(() => setIsCartOpen(false), [])
  const toggleCart = useCallback(() => setIsCartOpen((prev) => !prev), [])

  useEffect(() => {
    if (location) {
      hideCart()
    }
  }, [hideCart, location])

  return (
    <ToggleCartContext.Provider value={{ isCartOpen, toggleCart, showCart, hideCart }}>
      {children}
    </ToggleCartContext.Provider>
  )
}
