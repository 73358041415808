import {
  SetDispensaryPromoCodeOnCartDocument,
  SetDispensaryPromoCodeOnCartMutation,
  SetDispensaryPromoCodeOnCartMutationVariables,
} from '../../../graphql/magento'
import { UseCartMutation, useCartMutation } from './useCartMutation'

export type UseCartSetDispensaryPromoCode = [
  setDispensaryPromoCodeOnCart: CartMutation['0'],
  result: CartMutation['1'],
]

type CartMutation = UseCartMutation<
  SetDispensaryPromoCodeOnCartMutation,
  SetDispensaryPromoCodeOnCartMutationVariables
>

export const useCartSetDispensaryPromoCode = (): UseCartSetDispensaryPromoCode => {
  return useCartMutation(SetDispensaryPromoCodeOnCartDocument)
}
