import { isApolloError } from '@apollo/client'
import { bind } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'

type EScriptError = {
  _tag: 'InvalidPromoCode'
  error_message: string
}

type EScriptPromoCode = {
  code: string
}

export const invalidCouponCodeError = (err: unknown): string | undefined =>
  err instanceof Error && isApolloError(err)
    ? err.graphQLErrors.find((graphqlError) =>
        graphqlError.message.includes(`coupon code isn't valid`),
      )?.message
    : undefined

const [eScriptErrors$, setEScriptErrors] = createSignal<ReadonlyArray<EScriptError>>()

export { setEScriptErrors }

export const [useLatestEScriptErrors, latestEScriptErrors$] = bind(eScriptErrors$, undefined)

const [eScriptPromoUsed$, setEScriptPromoUsed] = createSignal<EScriptPromoCode | undefined>()

export { setEScriptPromoUsed }

export const [useLatestEScriptPromoUsed] = bind(eScriptPromoUsed$, undefined)
