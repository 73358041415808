import memoizeOne from 'memoize-one'

import { decodeJwt } from '../../utils/tokenTools'

export const tokenHasMagentoUserScope = memoizeOne((token: string | undefined) => {
  if (!token) {
    return false
  }
  const decodedToken = decodeJwt(token)
  const apiScopes = (decodedToken?.payload?.scope as string)?.split(' ') ?? []
  return apiScopes.includes('magento_user')
})
