import { logAndCaptureException } from './errorTools'

export class MemoryStorage implements Storage {
  private store = new Map<string, string>()

  clear(): void {
    this.store.clear()
  }

  getItem(key: string): string | null {
    return this.store.get(key) ?? null
  }

  setItem(key: string, value: string): void {
    this.store.set(key, value)
  }

  key(index: number): string | null {
    const keyIterator = this.store.keys()
    let result = keyIterator.next()
    let resultIndex = 0
    for (; resultIndex < index && !result.done; resultIndex++) {
      result = keyIterator.next()
    }
    return resultIndex === index && !result.done ? result.value ?? null : null
  }

  removeItem(key: string): void {
    this.store.delete(key)
  }

  get length(): number {
    return this.store.size
  }
}

export function isStorageDisabledError(err: unknown): boolean {
  return (
    err instanceof DOMException &&
    (err.code === DOMException.INVALID_ACCESS_ERR ||
      err.code === DOMException.QUOTA_EXCEEDED_ERR ||
      err.code === DOMException.SECURITY_ERR)
  )
}

export function getStorageOrElse<T>({
  name,
  onFailure,
}: {
  name: 'localStorage' | 'sessionStorage'
  onFailure: (err: unknown) => T
}): Storage | T {
  try {
    const testKey = '__storage-test__'
    const storage = window[name]
    storage.setItem(testKey, testKey)
    storage.removeItem(testKey)
    return storage
  } catch (err) {
    if (typeof window === 'undefined') {
      return onFailure(err)
    }
    console.error(`${name} not available:`, err instanceof Error ? err.message : err)

    // Avoid logging errors related to private browsing and user security settings
    if (!isStorageDisabledError(err)) {
      logAndCaptureException(err)
    }
    return onFailure(err)
  }
}

export const getNamedOrMemoryStorage = (name: 'localStorage' | 'sessionStorage'): Storage =>
  getStorageOrElse({ name, onFailure: () => new MemoryStorage() })

export function isMemoryStorage(value: Storage): value is MemoryStorage {
  return value instanceof MemoryStorage
}

export const localOrFallbackStorage = getNamedOrMemoryStorage('localStorage')

export const sessionOrFallbackStorage = getNamedOrMemoryStorage('sessionStorage')
