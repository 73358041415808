import { ParsedQuery } from 'query-string'

import { parseStringArrayParam, parseStringParam, QueryParamRaw } from '../../../../utils/url'
import { WellWorldParams, WellWorldProductParam } from './types'

export const parseProductSkusParam = (
  paramValue: QueryParamRaw,
): WellWorldProductParam[] | undefined => {
  const skus = parseStringArrayParam(paramValue, (value) => !!value)
  if (!skus.length) {
    return undefined
  }
  const uniqueSkus = Array.from(new Set(skus))

  return uniqueSkus.map((uniqueSku) => {
    return {
      productSku: uniqueSku,
      productQuantity: skus.filter((sku) => sku === uniqueSku).length,
    }
  })
}

export const getWellWorldParamsFromQuery = (queryParams: ParsedQuery): WellWorldParams => {
  const {
    partner: practitionerCode,
    promocode: promoCode,
    productId: productSkus,
    wwid: wellWorldId,
    storeId: wellWorldStoreId,
    practiceId: wellWorldPracticeId,
    practitionerId: wellWorldPractitionerId,
  } = queryParams

  return {
    practitionerCode: parseStringParam(practitionerCode),
    promoCode: parseStringParam(promoCode),
    products: parseProductSkusParam(productSkus),
    wellWorldId: parseStringParam(wellWorldId),
    wellWorldStoreId: parseStringParam(wellWorldStoreId),
    wellWorldPracticeId: parseStringParam(wellWorldPracticeId),
    wellWorldPractitionerId: parseStringParam(wellWorldPractitionerId),
  }
}

export const hasValidWellWorldProductsParam = (products: WellWorldParams['products']): boolean =>
  !!(
    products &&
    products.length > 0 &&
    products.every((product) => product.productQuantity > 0) &&
    products.every((product) => product.productSku)
  )

export const hasAnyWellWorldIdParam = (params: WellWorldParams): boolean =>
  !!(
    params.wellWorldId ||
    params.wellWorldStoreId ||
    params.wellWorldPracticeId ||
    params.wellWorldPractitionerId
  )
