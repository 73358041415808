import { TaxIdFormFragment } from '../graphql/gatsby'
import {
  UseSiteSettingsAndMetadata,
  useSiteSettingsAndMetadata,
} from './useSiteSettingsAndMetadata'

export type SiteSettings = NonNullable<UseSiteSettingsAndMetadata['sanitySiteSettings']>
// Replaces property 'taxIdFormVX' with the more generic 'taxIdForm' for backwards-compatibility
export type UseSiteSettings = Omit<SiteSettings, 'taxIdFormV2'> &
  Omit<TaxIdFormFragment, 'taxIdFormV2'> & {
    taxIdForm: TaxIdFormFragment['taxIdFormV2']
  }

export const useSiteSettingsFrom = ({
  sanitySiteSettings,
}: Pick<UseSiteSettingsAndMetadata, 'sanitySiteSettings'>): UseSiteSettings => {
  const { taxIdFormV2: taxIdForm, ...otherSettings } = sanitySiteSettings ?? {}
  return { ...otherSettings, taxIdForm }
}

const useSiteSettings = (): UseSiteSettings => useSiteSettingsFrom(useSiteSettingsAndMetadata())

export default useSiteSettings
