import { LiteClientConfig } from './client'

type DispensariesClientConfig = LiteClientConfig &
  Required<Pick<LiteClientConfig, 'apiVersion' | 'useCdn'>>

export const dispensariesSanityConfig: DispensariesClientConfig = {
  apiVersion: 'v2022-10-12',
  projectId: process.env.GATSBY_SANITY_PROJECT_ID_DISPENSARIES || '646i2f8h',
  dataset: process.env.GATSBY_SANITY_DATASET_DISPENSARIES || 'production',
  useCdn: true,
}
