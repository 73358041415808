import { useAuth0 } from '@auth0/auth0-react'
import { useMemo } from 'react'

import { localOrFallbackStorage } from '../../../utils/storage'
import { completedCartsConfig } from '../config'
import { CompletedCarts } from '../context'
import { CompletedCart, CompletedCartsListEntry, CompletedCartWithUserId } from '../types'
import { isCompletedCartWithUserId, reduceCompletedCartsListEntry, tryJsonParse } from '../utils'

function getCompletedCartsList(): Array<CompletedCartsListEntry> {
  const completedCartsRawValue = localOrFallbackStorage.getItem(completedCartsConfig.collectionKey)
  const completedCartsParsed = completedCartsRawValue
    ? tryJsonParse(completedCartsRawValue)
    : undefined
  return Array.isArray(completedCartsParsed)
    ? completedCartsParsed.reduce(reduceCompletedCartsListEntry, [])
    : []
}

const contextValueFor = (userId: string): CompletedCarts => {
  function getCompletedCartFromStorage({
    storageKey,
  }: CompletedCartsListEntry): CompletedCart | undefined {
    try {
      const storageItem = localOrFallbackStorage.getItem(storageKey)
      const parsedItem = storageItem ? JSON.parse(storageItem) : undefined
      if (!isCompletedCartWithUserId(parsedItem)) {
        console.error(`Invalid item fetched from storage (key: ${storageKey})`)
        return undefined
      }
      if (parsedItem.userId !== userId) {
        console.error(
          `Cart fetched from storage (key: ${storageKey}) related to different user: ${JSON.stringify(
            { actual: parsedItem.userId, expected: userId },
          )}`,
        )
        return undefined
      }
      return parsedItem
    } catch (err) {
      console.error(`Error getting storage item (key: ${storageKey}):`, err)
      return undefined
    }
  }

  return {
    find(
      props: { mode: 'cartId'; cartId: string } | { mode: 'completedAt'; completedAt: string },
    ): CompletedCart | undefined {
      const completedCarts = getCompletedCartsList()
      const findCallback: (listEntry: CompletedCartsListEntry) => boolean =
        props.mode === 'cartId'
          ? ({ cartId }) => cartId === props.cartId
          : ({ completedAt }) => completedAt === props.completedAt
      const completedCartEntry = completedCarts.find(findCallback)
      if (!completedCartEntry) {
        return undefined
      }
      return getCompletedCartFromStorage(completedCartEntry)
    },
    mostRecent(): CompletedCart | undefined {
      const completedCarts = getCompletedCartsList().sort((a, b) => {
        if (a.completedAt > b.completedAt) {
          return -1
        }
        return a.completedAt < b.completedAt ? 1 : 0
      })
      const [completedCartEntry] = completedCarts
      if (!completedCartEntry) {
        return undefined
      }
      return getCompletedCartFromStorage(completedCartEntry)
    },
    push(completedCart: CompletedCart) {
      const completedCarts = getCompletedCartsList()
      const newStorageKey = `${completedCartsConfig.resourceKeyPrefix}${completedCart.cart.id}`
      const otherEntries = completedCarts.filter(
        ({ cartId, completedAt, storageKey }) =>
          cartId !== completedCart.cart.id &&
          completedAt !== completedCart.completedAt &&
          storageKey !== newStorageKey,
      )
      const newCompletedCarts = [
        ...otherEntries,
        {
          cartId: completedCart.cart.id,
          completedAt: completedCart.completedAt,
          storageKey: newStorageKey,
        },
      ]
      const completedCartWithUserId: CompletedCartWithUserId = {
        ...completedCart,
        userId,
      }
      try {
        localOrFallbackStorage.setItem(newStorageKey, JSON.stringify(completedCartWithUserId))
        localOrFallbackStorage.setItem(
          completedCartsConfig.collectionKey,
          JSON.stringify(newCompletedCarts),
        )
      } catch (err: unknown) {
        console.error(`Error setting storage item:`, err)
      }
    },
  }
}

export function useCompletedCartsContextValue(): CompletedCarts {
  const { user } = useAuth0()

  // bind context functions to logged in user
  return useMemo(
    (): CompletedCarts =>
      user?.sub
        ? contextValueFor(user.sub)
        : {
            find() {
              console.error('CompletedCartsContext not initialized with user identity')
              return undefined
            },
            mostRecent() {
              console.error('CompletedCartsContext not initialized with user identity')
              return undefined
            },
            push() {
              console.error('CompletedCartsContext not initialized with user identity')
            },
          },
    [user?.sub],
  )
}
