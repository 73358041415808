import { catchError, map, Observable } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'

import { CustomError } from '../../utils/errorTools'
import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { fetchResponseToJsonResult, FetchResult } from '../fetch'

const apiHost = 'api.sanity.io'
const cdnHost = 'apicdn.sanity.io'
const postHeaders = {
  'content-type': 'application/json',
} as const

export interface LiteClientConfig {
  projectId: string
  dataset: string
  useCdn?: boolean
  token?: string
  apiHost?: string
  apiVersion?: string
  withCredentials?: boolean
}

export interface QueryParams {
  [key: string]: unknown
}

export interface RawQueryResponse<R> {
  result: R
}

export type { FetchLeft, FetchResult, FetchRight } from '../fetch'

export class LiteClientError extends CustomError {
  name = 'LiteClientError'
  public readonly responseBody?: unknown

  constructor(message: string, responseBody?: unknown) {
    super(message)
    if (responseBody) this.responseBody = responseBody
  }
}

/**
 * Lightweight, RxJS-based Sanity client for fetching data without requiring
 * @sanity/client package
 */
export class LiteClient {
  clientConfig: Readonly<LiteClientConfig>
  private readonly version: string
  private readonly authHeaders: { readonly Authorization?: string }

  constructor(config: Readonly<LiteClientConfig>) {
    this.clientConfig = config
    this.version = config.apiVersion ? `v${config.apiVersion.replace(/^v/, '')}` : 'v1'
    this.authHeaders = config.token ? { Authorization: `Bearer ${config.token}` } : {}
  }

  config(): Readonly<LiteClientConfig> {
    return this.clientConfig
  }

  fetch<R = unknown>(query: string): Observable<FetchResult<R>>
  fetch<R = unknown>(query: string, params: QueryParams | undefined): Observable<FetchResult<R>>
  fetch<R = unknown>(query: string, params?: QueryParams): Observable<FetchResult<R>> {
    const { dataset, projectId, useCdn, withCredentials } = this.clientConfig
    const version = this.version
    const queryString = buildQueryString(query, params)
    const usePost = queryString.length > 11264
    const headers = {
      ...this.authHeaders,
      ...(usePost && postHeaders),
    }

    const host = useCdn ? cdnHost : apiHost
    const url = `https://${projectId}.${host}/${version}/data/query/${dataset}${
      usePost ? '' : queryString
    }`

    return fromFetch(url, {
      headers,
      method: usePost ? 'POST' : 'GET',
      ...(usePost && { body: JSON.stringify({ query, params }) }),
      credentials: withCredentials ? 'include' : 'omit',
      // https://rxjs.dev/api/fetch/fromFetch#use-with-chunked-transfer-encoding
      selector: fetchResponseToJsonResult,
    }).pipe(
      map((value): FetchResult<R> => {
        if (value._tag === 'Left') {
          return value
        }
        const { data: responseBody } = value
        if (!isRawQueryResponse(responseBody)) {
          return {
            _tag: 'Left',
            error: new LiteClientError('Unable to decode Sanity response', responseBody),
          }
        }
        return {
          _tag: 'Right',
          data: responseBody.result as R,
        }
      }),
      catchError(coerceCaughtToLeft),
    )
  }
}

function buildQueryString(query: string, params: QueryParams | undefined): string {
  const queryString = `?query=${encodeURIComponent(query)}`
  if (!params) {
    return queryString
  }

  return Object.entries(params).reduce(
    (acc: string, [param, value]) =>
      `${acc}&${encodeURIComponent(`$${param}`)}=${encodeURIComponent(JSON.stringify(value))}`,
    queryString,
  )
}

function isRawQueryResponse(value: unknown): value is RawQueryResponse<unknown> {
  if (value == null || typeof value !== 'object') {
    return false
  }
  const partial = value as Partial<RawQueryResponse<unknown>>
  return partial.result !== undefined
}
