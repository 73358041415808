import { theme as dfhTheme } from '@designsforhealth/dfh-react-components'

// add any project specific colors, font families, etc. here
export const extendedTheme = {
  ...dfhTheme,
  colors: {
    ...dfhTheme.colors,
    component: {
      ...dfhTheme.colors.component,
      link: dfhTheme.colors.brand.red,
      textDark: '#051120',
      buttonDisabled: '#C9D6DF',
      checkoutBorderLight: '#D6D6D6',
    },
    coolGray: {
      ...dfhTheme.colors.coolGray,
      cool450: '#C4C7CC',
      cool550: '#A9B0B5',
    },
    brandBlue: {
      main: dfhTheme.colors.brand.blue,
      light100: '#C3CFDE',
      light200: '#DDE3E9',
      light300: '#EDEFF2',
      dark100: '#09203C',
      dark200: '#02162F',
      dark300: '#051120',
    },
    blue: {
      main: dfhTheme.colors.brand.blue,
      light100: '#103563',
      light200: '#154787',
      light300: '#1B5AAA',
      light350: '#2763CF',
      light400: '#206DCD',
      light500: '#0084F8',
      dark100: '#02080F',
    },
    brandRed: {
      main: dfhTheme.colors.brand.red,
      light100: '#D2423F',
      light200: '#DA6260',
      light300: '#E18381',
      dark050: '#DE4F56',
      dark100: '#8F2421',
      dark200: '#611817',
      dark300: '#340D0C',
    },
    brandGreen: {
      main: dfhTheme.colors.brand.categoryGreen,
      light100: '#5AAD75',
      light200: '#75BA8C',
      light300: '#91C8A3',
      dark100: '#376F49',
      dark200: '#254930',
      dark300: '#122418',
    },
    brandYellow: {
      main: dfhTheme.colors.brand.categoryYellow,
      light050: '#FFC44D',
      light100: '#F2E66F',
      light200: '#F5ED95',
      light300: '#F9F3BA',
      dark100: '#E9D717',
      dark200: '#B7A911',
      dark300: '#83790C',
    },
  },
  fontFamilies: {
    ...dfhTheme.fontFamilies,
    signature: "'Herr Von Muellerhoff', cursive",
  },
  fonts: {
    ...dfhTheme.fonts,
    heading: {
      sizes: [
        {
          // e.g. h5
          fontSize: 14,
        },
        {
          // e.g. h4
          fontSize: 16,
        },
        {
          // e.g. h3
          fontSize: 22,
        },
        {
          // e.g. h2
          fontSize: 24,
        },
        {
          // e.g. h1
          fontSize: 30,
        },
      ],
    },
  },
} as const

export type Theme = typeof extendedTheme
