import { ApolloError } from '@apollo/client'
import { useMemo } from 'react'

import { usePromisify } from '../../../hooks/usePromisify'
import { isMagentoScopeNotGrantedError } from '../../auth/errors'
import { useCartContext } from './useCartContext'
import { SetCartAttributesVariables } from './useCartSetAttributes'

export type UseCartGetAttributes = CartGetAttributesData & {
  loading: boolean
  error: ApolloError | Error | undefined
  loadCartAttributes: () => Promise<CartGetAttributesData>
}

export interface CartGetAttributesData {
  cartAttributes: SetCartAttributesVariables
  isWellWorldCart: boolean
}

export type WellWorldCartAttributes = Pick<
  SetCartAttributesVariables,
  'wellWorldId' | 'wellWorldStoreId' | 'wellWorldPracticeId' | 'wellWorldPractitionerId'
>

export const useCartGetAttributes = (): UseCartGetAttributes => {
  const { cart, loading, error } = useCartContext()

  const { cartAttributes, isWellWorldCart } = useMemo<CartGetAttributesData>(() => {
    if (loading || error) {
      return { isWellWorldCart: false, cartAttributes: {} }
    }
    const practitionerCode = cart?.practitioner_code
    const eScriptCampaign = cart?.e_script_campaign
    const wellWorldId = cart?.ww_id
    const wellWorldStoreId = cart?.ww_store_id
    const wellWorldPracticeId = cart?.ww_practice_id
    const wellWorldPractitionerId = cart?.ww_practitioner_id

    let wellWorldAttributes: WellWorldCartAttributes | undefined = undefined
    let hasWellWorldAttributes = false
    if (wellWorldId || wellWorldStoreId || wellWorldPracticeId || wellWorldPractitionerId) {
      wellWorldAttributes = {
        wellWorldId,
        wellWorldStoreId,
        wellWorldPracticeId,
        wellWorldPractitionerId,
      }
      hasWellWorldAttributes = true
    }

    return {
      cartAttributes: {
        practitionerCode: practitionerCode || '',
        eScriptCampaign: eScriptCampaign || '',
        ...(wellWorldAttributes || {}),
      },
      isWellWorldCart: hasWellWorldAttributes,
    }
  }, [
    loading,
    error,
    cart?.practitioner_code,
    cart?.ww_id,
    cart?.ww_store_id,
    cart?.ww_practice_id,
    cart?.ww_practitioner_id,
    cart?.e_script_campaign,
  ])

  const cartGetError = !isMagentoScopeNotGrantedError(error) ? error : undefined

  const { load: loadCartAttributes } = usePromisify<CartGetAttributesData>({
    data: { cartAttributes, isWellWorldCart },
    loading,
    error: cartGetError,
  })

  return { cartAttributes, isWellWorldCart, loading, error: cartGetError, loadCartAttributes }
}
