import { navigate } from 'gatsby'
import { Observable } from 'rxjs'

import { addDispensaryBasePathToPath } from '../utils/dispensaries'
import { DispensaryExternalEffectRedirect } from './types'

/**
 * Given a `redirect` external effect, return an observable that uses the Gatsby navigate method
 * to redirect the user to the current path in their assigned dispensary.
 */
export const handleExternalRedirectEffect = (
  effect: DispensaryExternalEffectRedirect,
): Observable<never> =>
  new Observable((subscriber) => {
    // take the user to the same page in their dispensary
    const path = typeof window !== 'undefined' ? window.location.pathname : '/'
    const redirectPath = addDispensaryBasePathToPath(effect.dispensary.slug, path)
    // Use Gatsby's navigate method to replace the url
    navigate(redirectPath, { replace: true })
    subscriber.complete()
  })
