import {
  SetCartAttributesDocument,
  SetCartAttributesMutation,
  SetCartAttributesMutationVariables,
} from '../../../graphql/magento'
import { CartMutationVariables, UseCartMutation, useCartMutation } from './useCartMutation'

export type UseCartSetAttributes = [setCartAttributes: CartMutation['0'], result: CartMutation['1']]

export type SetCartAttributesVariables = CartMutationVariables<SetCartAttributesMutationVariables>

type CartMutation = UseCartMutation<SetCartAttributesMutation, SetCartAttributesMutationVariables>

const isValidVariables = (
  variables?: CartMutationVariables<SetCartAttributesMutationVariables>,
): variables is CartMutationVariables<SetCartAttributesMutationVariables> => {
  const mutationVariables = variables || {}
  return !!(
    mutationVariables.practitionerCode ||
    'dispensaryPromoCode' in mutationVariables ||
    'wellWorldId' in mutationVariables ||
    'wellWorldStoreId' in mutationVariables ||
    'wellWorldPracticeId' in mutationVariables ||
    'wellWorldPractitionerId' in mutationVariables ||
    'eScriptCampaign' in mutationVariables
  )
}

export const useCartSetAttributes = (): UseCartSetAttributes => {
  return useCartMutation(SetCartAttributesDocument, {
    isValidVariables,
  })
}
