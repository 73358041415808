import { useLocation } from '@gatsbyjs/reach-router'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { usePromisify } from '../../../../hooks/usePromisify'
import { useRemoveParamsFromUrl } from '../../../../hooks/useRemoveParamsFromUrl'
import {
  EScriptCartAttributes,
  EScriptParamsData,
  EScriptParamsLoader,
  EScriptUpdateCartAction,
} from '../../types'
import { eScript20230411AnonymousCampaign, eScript20230411ParamsToRemove } from './config'
import { getEScript20230411ParamsFromQuery, hasValidEScript20230411ProductsParam } from './parser'
import { EScript20230411Params } from './types'

export const useEScript20230411ParamsLoader = (shouldRun = true): EScriptParamsLoader => {
  const [loading, setLoading] = useState(shouldRun)
  const [params, setParams] = useState<EScript20230411Params>({ version: '2023-04-11' })

  const { search, href } = useLocation()
  const eScriptLink = href
  const removeParamsFromUrl = useRemoveParamsFromUrl()

  const parsedParams = useMemo<EScript20230411Params>(() => {
    if (!shouldRun) {
      return { version: '2023-04-11' }
    }
    const queryParams = queryString.parse(search || '', { arrayFormat: 'comma' })
    return getEScript20230411ParamsFromQuery(queryParams)
  }, [shouldRun, search])

  const shouldAddToCart = useMemo(() => {
    return shouldRun && !loading && hasValidEScript20230411ProductsParam(params.products)
  }, [shouldRun, loading, params.products])

  const updateCartActions = useMemo<EScriptUpdateCartAction[] | undefined>(() => {
    if (loading) {
      return undefined
    }
    if (shouldAddToCart && params.products) {
      return [{ operation: 'empty' }, { operation: 'add', products: params.products }]
    }
    return undefined
  }, [loading, params.products, shouldAddToCart])

  const affiliateId = useMemo(
    () => (shouldRun ? `dfh_${params.version}` : undefined),
    [shouldRun, params.version],
  )

  const cartAttributes = useMemo<EScriptCartAttributes | undefined>(() => {
    if (loading) {
      return undefined
    }
    return {
      type: 'eScriptCampaign',
      attributes: {
        eScriptCampaign: params.eScriptCampaign || eScript20230411AnonymousCampaign,
      },
    }
  }, [loading, params.eScriptCampaign])

  useEffect(() => {
    if (!shouldRun) {
      return
    }
    const parsedParamsEntries = Object.entries(parsedParams)
    if (parsedParamsEntries.length === 0) {
      return
    }
    setParams((prevParams) =>
      parsedParamsEntries.reduce(
        (acc: EScript20230411Params, [key, value]) =>
          // if parsed params are emptied, keep the values in state, but if url params are updated
          // with non-empty values, update the state
          value ? { ...acc, [key]: value } : acc,
        prevParams,
      ),
    )
    setLoading(false)
  }, [parsedParams, shouldRun])

  const paramsData = useMemo<EScriptParamsData>(
    () => ({
      version: params.version,
      updateCartActions,
      promoCode: params.promoCode,
      affiliateId,
      cartAttributes,
      link: eScriptLink,
    }),
    [params.version, params.promoCode, updateCartActions, affiliateId, cartAttributes, eScriptLink],
  )

  const { load: loadParams } = usePromisify<EScriptParamsData>({
    loading,
    error: undefined,
    data: paramsData,
  })

  const clearParams = useCallback(
    () => (shouldRun ? removeParamsFromUrl(eScript20230411ParamsToRemove) : undefined),
    [removeParamsFromUrl, shouldRun],
  )

  return useMemo(() => ({ loading, loadParams, clearParams }), [clearParams, loadParams, loading])
}
