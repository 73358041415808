import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import { setContext, setUser } from '@sentry/browser'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { catchError, from } from 'rxjs'
import { tap } from 'rxjs/operators'

import useSiteMetadata from '../hooks/useSiteMetadata'
import { TokenContext } from '../layouts/context'
import { latestAccessTokenExpiration$ } from '../lib/auth/expiration'
import {
  latestAccessToken$,
  latestAccessTokenFactory$,
  latestAuthState$,
  latestAuthStateWithAccessToken$,
  latestLabelName$,
  setAccessToken,
  setAccessTokenFactory,
  setAuthState,
} from '../lib/auth/state'
import { dispensaryEffects$ } from '../lib/dispensaries/effects'
import { userClaimIds } from '../utils/userClaims'

const authStateContext = ({
  isAuthenticated,
  isLoading,
  error: authError,
}: Auth0ContextInterface): { [key: string]: unknown } => ({
  isAuthenticated,
  isLoading,
  error: authError && {
    name: authError.name,
    message: authError.message,
    stack: authError.stack,
  },
})

export interface TokenContextProviderProps {
  children: React.ReactNode
}

const TokenContextProvider: React.FC<TokenContextProviderProps> = ({ children }) => {
  const auth0Context = useAuth0()
  const { getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated, isLoading, user } =
    auth0Context
  const [token, setToken] = useState<string | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      try {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently()

          if (accessToken) {
            setToken(accessToken)
          } else {
            setToken(undefined)
          }
        } else {
          setToken(undefined)
        }
      } catch (accessTokenError) {
        setToken(undefined)
      }
    })()
  }, [isAuthenticated, getAccessTokenSilently])

  // update Sentry user for error reporting
  useEffect(() => {
    setUser(
      user
        ? {
            id: user[userClaimIds.netSuiteId],
            auth0_id: user.sub,
          }
        : null,
    )
  }, [user])

  // update Sentry context for error reporting
  useEffect(() => {
    setContext('Auth State', authStateContext(auth0Context))
  }, [auth0Context])

  useEffect(() => {
    const subscriptions = [
      latestAuthState$.subscribe(),
      latestAccessToken$.subscribe(),
      latestAccessTokenFactory$.subscribe(),
      latestAuthStateWithAccessToken$.subscribe(),
      latestAccessTokenExpiration$.subscribe(),
      // hold subscription here to enable automatic dispensary redirects and notifications
      dispensaryEffects$.subscribe(),
    ]
    return () => subscriptions.forEach((subscription) => subscription.unsubscribe())
  }, [])

  const { featureFlags } = useSiteMetadata() ?? {}
  useEffect(() => {
    if (featureFlags?.productLabelStatus) {
      const subscription = latestLabelName$.subscribe()
      return () => subscription.unsubscribe()
    }
  }, [featureFlags])

  useEffect(() => {
    setAuthState({ isAuthenticated, isLoading, user })
  }, [isAuthenticated, isLoading, user])

  useEffect(() => {
    setAccessToken(token)
  }, [token])

  useEffect(() => {
    setAccessTokenFactory((options) =>
      from(getAccessTokenSilently(options)).pipe(
        catchError((err: unknown) => {
          if (
            err != null &&
            typeof err === 'object' &&
            (err as { error?: unknown }).error === 'consent_required'
          ) {
            return from(getAccessTokenWithPopup(options))
          }
          throw err
        }),
        tap((accessToken) => {
          if (options?.updateTokenContext) {
            setToken(accessToken)
          }
        }),
      ),
    )
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setToken])

  return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
}

TokenContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TokenContextProvider
