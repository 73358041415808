import { useCallback, useEffect, useState } from 'react'

import useIsMounted from '../../../hooks/useIsMounted'
import useSiteSettings from '../../../hooks/useSiteSettings'
import { useEScriptParamsLoader } from '../../escript/hooks/useEScriptParamsLoader'
import { dispensaryPractitionerCodes } from '../config'
import type { Dispensary } from '../types'
import { sanitizeUrlSlug } from '../utils'

export interface UseInvalidUrlDispensary {
  loading: boolean
  invalidUrlDispensaryForSlug: (slug: string) => Dispensary | undefined
}

const { invalidDispensaryUrl } = dispensaryPractitionerCodes
const { env: envPractitionerCode, defaultValues, wellWorldPrefix } = invalidDispensaryUrl

function isKeyOfDefaultValues(
  value: string | undefined | null,
): value is keyof typeof defaultValues {
  return !!value && value in defaultValues
}

export const useInvalidUrlDispensary = (): UseInvalidUrlDispensary => {
  const { lang } = useSiteSettings() || {}
  const { loadParams } = useEScriptParamsLoader()
  const isMounted = useIsMounted()

  const [loading, setLoading] = useState(true)
  const [hasWellWorldParams, setHasWellWorldParams] = useState(false)

  const invalidUrlDispensaryForSlug = useCallback(
    (rawSlug: string): Dispensary | undefined => {
      if (loading) {
        return undefined
      }
      const slug = sanitizeUrlSlug(rawSlug)
      const defaultPractitionerCode = isKeyOfDefaultValues(lang)
        ? defaultValues[lang]
        : defaultValues.default
      const practitionerCode = envPractitionerCode || defaultPractitionerCode
      const wellWorldPractitionerCode = `${wellWorldPrefix}${practitionerCode}`
      const practitionerCodeToUse = hasWellWorldParams
        ? wellWorldPractitionerCode
        : practitionerCode
      return {
        isInvalid: true,
        name: slug,
        slug,
        practitionerCode: practitionerCodeToUse,
        practitionerCodeForOrder: `${practitionerCodeToUse} (${slug})`,
        practitionerCodeForWellWorldOrder: `${wellWorldPractitionerCode} (${slug})`,
      }
    },
    [loading, hasWellWorldParams, lang],
  )

  useEffect(() => {
    loadParams()
      .then(({ cartAttributes }) => {
        if (isMounted.current) {
          setHasWellWorldParams(cartAttributes?.type === 'wellWorld')
          setLoading(false)
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      })
  }, [isMounted, loadParams])

  return { loading, invalidUrlDispensaryForSlug }
}
