import { NavigateOptions as GatsbyNavigateOptions } from '@gatsbyjs/reach-router'
import { navigate as gatsbyNavigate } from 'gatsby'
import * as R from 'ramda'
import { useMemo } from 'react'

import { Dispensary } from '../../dispensaries'
import { useDispensaryContext } from '../../dispensaries/hooks/useDispensaryContext'
import { addDispensaryBasePathToPath } from '../../dispensaries/utils'
import { PageUrlOptions } from '../types'
import { getUrlForNormalizedPath, normalizePath } from '../utils'

export interface UsePageUrl {
  /**
   * Return normalized path (include `/u/:slug` prefix if within dispensary context).
   * Do NOT use for router base path - will break case-insensitive dispensary slug matching.
   */
  getPagePath: (pagePath?: string, options?: PageUrlOptions) => string
  getPageUrl: (pagePath?: string, options?: PageUrlOptions) => string
  navigate: <TState extends Record<string, unknown> = Record<string, unknown>>(
    pagePath: string,
    options?: NavigateOptions<TState>,
  ) => Promise<void>
}

export type NavigateOptions<TState extends Record<string, unknown> = Record<string, unknown>> =
  PageUrlOptions & GatsbyNavigateOptions<TState>

const defaultPageUrlOptions: Required<PageUrlOptions> = {
  forceBase: false,
}

const parsePageUrlOptions = (options?: PageUrlOptions): Required<PageUrlOptions> => {
  return {
    ...defaultPageUrlOptions,
    ...(options || {}),
  }
}

const getPagePathForDispensary =
  (dispensary: Dispensary | null): UsePageUrl['getPagePath'] =>
  (pagePath?: string, options?: PageUrlOptions) => {
    const { forceBase } = parsePageUrlOptions(options)
    const isBaseOnlyPath = !!pagePath && pagePath.startsWith('/api/')
    const path =
      !forceBase && dispensary && !isBaseOnlyPath
        ? addDispensaryBasePathToPath(dispensary.slug, pagePath)
        : pagePath
    return normalizePath(path)
  }

const getPageUrlForDispensary: (dispensary: Dispensary | null) => UsePageUrl['getPageUrl'] = R.pipe(
  getPagePathForDispensary,
  (getPagePath) => R.pipe(getPagePath, getUrlForNormalizedPath),
)

const navigateForDispensary: (dispensary: Dispensary | null) => UsePageUrl['navigate'] = R.pipe(
  getPagePathForDispensary,
  (getPagePath) => async (pagePath, options) => {
    const { forceBase, ...navigateOptions } = options || {}
    const path = getPagePath(pagePath, { forceBase })
    return gatsbyNavigate(path, navigateOptions)
  },
)

export const usePageUrl = (): UsePageUrl => {
  const dispensary = useDispensaryContext()

  return useMemo(
    () => ({
      getPagePath: getPagePathForDispensary(dispensary),
      getPageUrl: getPageUrlForDispensary(dispensary),
      navigate: navigateForDispensary(dispensary),
    }),
    [dispensary],
  )
}
