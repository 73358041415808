export interface LeftResult<E = Error> {
  _tag: 'Left'
  error: E
}

export interface RightResult<T> {
  _tag: 'Right'
  data: T
}

export type Result<T, E> = LeftResult<E> | RightResult<T>

export const right = <T>(data: T) => ({ _tag: 'Right' as const, data })

export const left = <E>(error: E) => ({ _tag: 'Left' as const, error })

export const isRight = <T, E>(result: Result<T, E>): result is RightResult<T> =>
  result._tag === 'Right'

export const isLeft = <T, E>(result: Result<T, E>): result is LeftResult<E> =>
  result._tag === 'Left'

export const pluckData = <T>(result: RightResult<T>): T => result.data
