export const wellWorldParamsToRemove = [
  'partner',
  'promocode',
  'productId',
  'wwid',
  'storeId',
  'practiceId',
  'practitionerId',
  'qty',
] as const
