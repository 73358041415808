import { useCallback } from 'react'

import { CartFragment } from '../../../../graphql/magento'
import { CartUpdateAttributesVariables, useCartUpdateAttributes } from './useCartUpdateAttributes'

export type UseCartUpdateAfterMerge = {
  updateCartAfterMerge: (options: UpdateCartAfterMergeOptions) => Promise<CartFragment>
}

export type UpdateCartAfterMergeOptions = {
  token: string
  mergedCart: CartFragment
  cartDataForUpdate: CartDataForUpdate
}

export type CartDataForUpdate = {
  cartAttributes: CartUpdateAttributesVariables
}

export type { CartUpdateAttributesVariables }

export const useCartUpdateAfterMerge = (): UseCartUpdateAfterMerge => {
  const { updateCartAttributes } = useCartUpdateAttributes()

  const updateCartAfterMerge = useCallback(
    async ({
      token,
      mergedCart,
      cartDataForUpdate: { cartAttributes },
    }: UpdateCartAfterMergeOptions): Promise<CartFragment> => {
      const { id: cartId } = mergedCart
      const updatedCart = await updateCartAttributes({
        token,
        cartId,
        cartAttributes,
      })
      if (updatedCart) {
        return updatedCart
      }
      return mergedCart
    },
    [updateCartAttributes],
  )

  return { updateCartAfterMerge }
}
