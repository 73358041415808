import { ApolloClient } from '@apollo/client'
import { state } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { createContext } from 'react'
import { map } from 'rxjs'

export interface ApolloClients {
  comma: ApolloClient<object>
  magento: ApolloClient<object>
}

export const ApolloClientsContext = createContext<ApolloClients>({} as ApolloClients)

const [apolloClients$, setApolloClients] = createSignal<ApolloClients>()

export { setApolloClients }

export const latestApolloClients$ = state(apolloClients$)
export const latestMagentoClient$ = latestApolloClients$.pipe(map(({ magento }) => magento))
export const latestCommaClient$ = latestApolloClients$.pipe(map(({ comma }) => comma))

// retain default alias to Magento client for backwards compatibility (and to match ApolloContext)
export const latestApolloClient$ = latestMagentoClient$
