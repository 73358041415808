import { useEffect, useMemo } from 'react'

import { CartFragment } from '../../../../graphql/magento'
import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import type { UseAuth } from '../../../auth'
import { areCartsEqual, isCartNotFoundError, isCartNotFoundGraphQlErrors } from '../../utils'
import { actions, UseCartGetReducer } from '../useCartGetReducer'
import { UseCartMerge, useCartMerge } from '../useCartMerge'
import { CartDataForUpdate, useCartUpdateAfterMerge } from '../useCartUpdateAfterMerge'
import { useGuestCartDataToUpdateAfterMerge } from '../useGuestCartDataToUpdateAfterMerge'
import type { UseGuestCartGet } from '../useGuestCartGet'

export const useMergeCartActions = ({
  reducer,
  authState,
  clearGuestCartId,
}: {
  reducer: UseCartGetReducer
  authState: UseAuth
  clearGuestCartId: UseGuestCartGet['2']['clearCartId']
}): UseCartMerge => {
  const [state, dispatch] = reducer
  const { guestCartId } = state

  const {
    cartDataForUpdate,
    error: cartDataForUpdateError,
    loading: cartDataForUpdateLoading,
  } = useGuestCartDataToUpdateAfterMerge({ guestCartId })
  const [mergeCarts, mergeCartState] = useCartMerge()
  const { updateCartAfterMerge } = useCartUpdateAfterMerge()

  const { error: mergeCartError, ...mergeCartStateRest } = mergeCartState

  const isCartNotFound = useMemo(() => isCartNotFoundError(mergeCartError), [mergeCartError])
  const error = isCartNotFound ? undefined : mergeCartError

  useLogAndCaptureError(error)

  // handle merge carts
  useEffect(() => {
    if (authState.isLoading) {
      return
    }
    if (
      state.loadingAuth ||
      state.type !== 'customer' ||
      state.mergeStatus !== 'initial' ||
      state.error ||
      !state.cart ||
      !state.guestCartId ||
      state.cart.id === state.guestCartId
    ) {
      return
    }
    if (mergeCartState.loading) {
      return
    }
    if (error) {
      dispatch(actions.mergeError(error))
      return
    }
    if (areCartsEqual(state.cart, mergeCartState.cart)) {
      return
    }
    if (mergeCartState.cart) {
      return
    }
    if (mergeCartState.called) {
      return
    }
    if (cartDataForUpdateLoading) {
      return
    }
    if (cartDataForUpdateError && !isCartNotFoundError(cartDataForUpdateError)) {
      dispatch(actions.mergeError(cartDataForUpdateError))
      return
    }
    dispatch(actions.mergeRequest())
    ;(async (
      sourceCartId: string,
      destinationCartId: string,
      {
        cart,
        cartDataForUpdateAfterMerge,
      }: { cart: CartFragment; cartDataForUpdateAfterMerge: CartDataForUpdate | undefined },
    ) => {
      try {
        const token = await authState.getAccessTokenSilently()
        const { cart: mergedCart, errors } = await mergeCarts({
          variables: { sourceCartId, destinationCartId },
          context: { token },
        })
        if (mergedCart) {
          const updatedCart = cartDataForUpdateAfterMerge
            ? await updateCartAfterMerge({
                mergedCart,
                token,
                cartDataForUpdate: cartDataForUpdateAfterMerge,
              })
            : mergedCart
          dispatch(actions.mergeComplete(updatedCart))
          clearGuestCartId()
        } else if (isCartNotFoundGraphQlErrors(errors, sourceCartId)) {
          dispatch(actions.mergeComplete(cart))
          clearGuestCartId()
        } else {
          dispatch(
            actions.mergeError(
              new Error(
                `Unable to merge carts. Details: ${JSON.stringify({
                  sourceCartId,
                  destinationCartId,
                  errors,
                })}`,
              ),
            ),
          )
        }
      } catch (mergeErrorUnknown) {
        const mergeError =
          mergeErrorUnknown instanceof Error ? mergeErrorUnknown : new Error(`${mergeErrorUnknown}`)
        if (isCartNotFoundError(mergeError, sourceCartId)) {
          dispatch(actions.mergeComplete(cart))
        } else {
          dispatch(actions.mergeError(mergeError))
        }
      }
    })(state.guestCartId, state.cart.id, {
      cart: state.cart,
      cartDataForUpdateAfterMerge: cartDataForUpdate,
    })
  }, [
    dispatch,
    state,
    authState,
    error,
    mergeCartState,
    mergeCarts,
    clearGuestCartId,
    cartDataForUpdateLoading,
    cartDataForUpdate,
    updateCartAfterMerge,
    cartDataForUpdateError,
  ])

  return [mergeCarts, { error, ...mergeCartStateRest }]
}
