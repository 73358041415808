import { bind } from '@react-rxjs/core'
import { map } from 'rxjs/operators'

import { userClaimIds } from '../../utils/userClaims'
import { latestAuthState$ } from '../auth/state'

export type MagentoUserGroup = (typeof magentoUserGroups)[number]

const magentoUserGroups = [
  'Retail',
  'Wholesale',
  'Wholesale-2',
  'Distributor',
  'Distributor-2',
  'Distributor-3 (20% off)',
  'International - Distributor 15%',
  'International - Distributor 35%',
  'Patient Tier 1 - PP1',
  'Patient Tier 2 - PP2',
  'Patient Tier 3 - PP3',
  'Patient Wholesale',
] as const

const isValidMagentoUserGroup = (userGroup: string): userGroup is MagentoUserGroup =>
  magentoUserGroups.includes(userGroup as MagentoUserGroup)

/**
 * Extract the Magento user group from claims in the latest user token.
 *
 * Returns `undefined` if claim not found, not valid, or user not authenticated.
 */
export const latestMagentoUserGroup$ = latestAuthState$.pipe(
  map(({ user }) => (user ? (user[userClaimIds.magentoUserGroup] as unknown) : undefined)),
  map((userGroup) =>
    typeof userGroup === 'string' && isValidMagentoUserGroup(userGroup) ? userGroup : undefined,
  ),
)

export const [useMagentoUserGroup] = bind(latestMagentoUserGroup$)

export const [useShowAutoShipAndSaveToUserGroup] = bind(
  latestMagentoUserGroup$.pipe(map((userGroup) => userGroup !== 'Patient Wholesale')),
)
