import {
  RemoveCouponFromCartDocument,
  RemoveCouponFromCartMutation,
  RemoveCouponFromCartMutationVariables,
} from '../../../graphql/magento'
import { UseCartMutation, useCartMutation } from './useCartMutation'

export type UseCartRemoveCoupon = [
  removeCouponFromCart: CartMutation['0'],
  result: CartMutation['1'],
]

type CartMutation = UseCartMutation<
  RemoveCouponFromCartMutation,
  RemoveCouponFromCartMutationVariables
>

export const useCartRemoveCoupon = (): UseCartRemoveCoupon => {
  return useCartMutation(RemoveCouponFromCartDocument)
}
