import { Observable } from 'rxjs'

import { SanityQueryDefinition } from '../../groq/sanity-dispensaries/types'
import { EmptyObject } from '../../utils/types'
import { FetchResult, LiteClient } from '../sanity/client'
import { dispensariesSanityConfig } from '../sanity/config'

export const dispensariesClientCdn = new LiteClient(dispensariesSanityConfig)
export const dispensariesClientLive = new LiteClient({ ...dispensariesSanityConfig, useCdn: false })

export const fetchQuery =
  <TData, TParams extends EmptyObject | undefined = undefined>(
    queryDefinition: SanityQueryDefinition<TData, TParams>,
    params: TParams,
  ) =>
  (client: LiteClient): Observable<FetchResult<TData>> =>
    client.fetch<TData>(queryDefinition.query, params)
