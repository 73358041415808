import * as R from 'ramda'

export const sanitizeInput = R.pipe(R.toUpper, R.trim)

export const identityPromise = <T>(val: T): Promise<T> => Promise.resolve(val)

export function checkCryptoApiAvailable(): Promise<void> {
  if (typeof window?.crypto === 'undefined') {
    return Promise.reject(new Error('Web Crypto API not available'))
  }
  return Promise.resolve()
}

export const encodeUtf8ByteArray = (text: string): Uint8Array => new TextEncoder().encode(text)

export const decodeUtf8ArrayBuffer = (byteArray: ArrayBuffer): string =>
  new TextDecoder().decode(byteArray)
