import { ApolloError, useLazyQuery } from '@apollo/client'
import { useCallback, useContext, useEffect, useState } from 'react'

import { CartFragment, GetCustomerCartDocument } from '../../../graphql/magento'
import { TokenContext } from '../../../layouts/context'
import { MagentoScopeNotGrantedError } from '../../auth/errors'
import { tokenHasMagentoUserScope } from '../../auth/scope'

export type UseCustomerCartGet = [
  getCustomerCart: (shouldRefetch: boolean) => void,
  state: {
    cart?: CartFragment
    loading: boolean
    error?: ApolloError | Error | MagentoScopeNotGrantedError
    called: boolean
  },
]

export const useCustomerCartGet = (): UseCustomerCartGet => {
  const token = useContext(TokenContext)
  // use single state hook to hold `called` and `localError`
  const [{ called, localError }, setState] = useState<{
    called: boolean
    localError: MagentoScopeNotGrantedError | undefined
  }>(() => ({ called: false, localError: undefined }))
  const hasMagentoUserScope = tokenHasMagentoUserScope(token)

  const [doGetCustomerCart, { data, loading, error, refetch }] = useLazyQuery(
    GetCustomerCartDocument,
    {
      context: { token },
      // return partial data if available
      errorPolicy: 'all',
    },
  )

  const { customerCart: cart } = data || {}

  const getCustomerCart = useCallback(
    (shouldRefetch: boolean) => {
      shouldRefetch
        ? refetch()
        : // toggle `called` to trigger useEffect
          setState((prevState) => ({ ...prevState, called: true }))
    },
    [refetch],
  )

  useEffect(() => {
    if (!called || !token) {
      return
    }
    if (!hasMagentoUserScope) {
      setState((prevState) => ({ ...prevState, localError: new MagentoScopeNotGrantedError() }))
    } else {
      doGetCustomerCart()
      // reset `localError` if necessary (will likely never happen - included for completeness)
      setState((prevState) =>
        !prevState.localError ? prevState : { ...prevState, localError: undefined },
      )
    }
  }, [called, token, doGetCustomerCart, hasMagentoUserScope])

  return [
    getCustomerCart,
    {
      cart,
      loading,
      error: localError || error,
      called,
    },
  ]
}
