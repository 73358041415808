import { bind } from '@react-rxjs/core'
import { concatMap, distinctUntilChanged, of, startWith, switchScan, timer } from 'rxjs'

import { latestAuthStateWithAccessToken$ } from '../auth/state'

export interface UtilityMenuState {
  isAnimated: boolean
  isAuthenticated: boolean
  isVisible: boolean
}

const initialState: UtilityMenuState = {
  isAnimated: true,
  isAuthenticated: false,
  isVisible: false,
}

export const [useUtilityMenuState, utilityMenuState$] = bind<UtilityMenuState>(
  // use `latestAuthStateWithAccessToken$` since it waits for authentication to settle before emitting
  latestAuthStateWithAccessToken$.pipe(
    switchScan(
      (prevState, { isAuthenticated }) =>
        // if already visible or not animated, just return the new state
        prevState.isVisible || !prevState.isAnimated
          ? of({
              isAnimated: false,
              isAuthenticated,
              isVisible: true,
            })
          : timer(1000).pipe(
              concatMap(() =>
                // disable animation after waiting for animation to complete (1s should be plenty)
                of({
                  isAnimated: false,
                  isAuthenticated,
                  isVisible: true,
                }),
              ),
              // emit initial state with animation enabled
              startWith({
                isAnimated: true,
                isAuthenticated,
                isVisible: true,
              }),
            ),
      initialState,
    ),
    distinctUntilChanged(
      (a, b) =>
        a.isVisible === b.isVisible &&
        a.isAnimated === b.isAnimated &&
        a.isAuthenticated === b.isAuthenticated,
    ),
  ),
  // specify initial value to avoid emitting Suspense
  initialState,
)
