export class Deferred<TResult = unknown, TError = Error> {
  promise: Promise<TResult>

  isCompleted = false

  resolve: (value: TResult) => void = () => {
    // empty function that will be replaced immediately in Promise constructor
  }

  reject: (value: TError) => void = () => {
    // empty function that will be replaced immediately in Promise constructor
  }

  constructor() {
    this.promise = new Promise<TResult>((resolve, reject) => {
      this.resolve = (value) => {
        if (this.isCompleted) {
          return
        }
        this.isCompleted = true
        resolve(value)
      }
      this.reject = (value) => {
        if (this.isCompleted) {
          return
        }
        this.isCompleted = true
        reject(value)
      }
    })
  }
}
