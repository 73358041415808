import { useAuth0 } from '@auth0/auth0-react'
import { setContext } from '@sentry/gatsby'
import { DispatchWithoutAction, useCallback, useEffect } from 'react'

import type { CartGetState } from '../../reducers/cart-get-state'
import { UseCartGetReducer, useCartGetReducer } from '../useCartGetReducer'
import { useAuthActions } from './useAuthActions'
import { useCustomerCartActions } from './useCustomerCartActions'
import { useGuestCartActions } from './useGuestCartActions'
import { useGuestCartAllowedActions } from './useGuestCartAllowedActions'
import { useMergeCartActions } from './useMergeCartActions'

export type UseCartGet = Pick<CartGetState, 'cart' | 'loading' | 'error'> & {
  refetch: DispatchWithoutAction
}

export interface UseCartGetOptions {
  allowGuestCart?: boolean
}

const cartStateContext = ([{ cart, ...cartStateRest }]: UseCartGetReducer): {
  [key: string]: unknown
} => ({ cartId: cart?.id, ...cartStateRest })

export const useCartGet = ({ allowGuestCart }: UseCartGetOptions = {}): UseCartGet => {
  const reducer = useCartGetReducer()
  const [state, dispatch, actions] = reducer
  const { cart, loading, error } = state

  // update Sentry context for error reporting
  useEffect(() => {
    setContext('Cart State', cartStateContext(reducer))
  }, [reducer])

  const authState = useAuth0()

  // dispatch session actions
  useAuthActions({ reducer })

  // dispatch guest-allowed actions
  useGuestCartAllowedActions({ reducer, allowGuestCart })

  // dispatch guest cart actions
  const [, , { clearCartId: clearGuestCartId }] = useGuestCartActions({
    reducer,
    authState,
  })

  // dispatch customer cart actions
  useCustomerCartActions({ reducer, authState })

  // dispatch merge carts actions
  useMergeCartActions({ reducer, authState, clearGuestCartId })

  // Disabling ESLint rule for deps since `dispatch` is guaranteed to be stable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetch = useCallback(() => dispatch(actions.refetch()), [dispatch])

  return { cart, loading, error, refetch }
}
