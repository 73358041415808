import { useCallback, useEffect, useRef } from 'react'

import { Deferred } from '../utils/deferred'

export interface UsePromisify<TData> {
  load: () => Promise<TData>
}

export interface UsePromisifyOptions<TData> {
  data: TData
  loading: boolean
  error?: Error | undefined
}

export const usePromisify = <TData>({
  loading,
  error,
  data,
}: UsePromisifyOptions<TData>): UsePromisify<TData> => {
  const deferredRef = useRef(new Deferred<TData>())

  useEffect(() => {
    const deferred = deferredRef.current
    if (deferred.isCompleted) {
      return
    }
    if (loading) {
      return
    }
    if (error) {
      deferred.reject(error)
      return
    }
    deferred.resolve(data)
  }, [loading, error, data])

  const load = useCallback(async (): Promise<TData> => deferredRef.current.promise, [])

  return { load }
}
