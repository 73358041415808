import {
  AddSimpleProductsToCartDocument,
  AddSimpleProductsToCartMutation,
  AddSimpleProductsToCartMutationVariables,
} from '../../../graphql/magento'
import { UseCartMutation, useCartMutation } from './useCartMutation'

export type UseCartAddSimpleProducts = [
  addSimpleProductsToCart: CartMutation['0'],
  result: CartMutation['1'],
]

type CartMutation = UseCartMutation<
  AddSimpleProductsToCartMutation,
  AddSimpleProductsToCartMutationVariables
>

export const useCartAddSimpleProducts = (): UseCartAddSimpleProducts => {
  return useCartMutation(AddSimpleProductsToCartDocument)
}
