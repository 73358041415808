import { ApolloClient } from '@apollo/client'
import { catchError, distinctUntilKeyChanged, EMPTY, filter, from, map, of, switchMap } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

import {
  GetCustomerSettingsPractitionerDocument,
  GetCustomerSettingsPractitionerQueryVariables,
  PublishCustomerProvisioningNeededDocument,
  PublishCustomerProvisioningNeededMutationVariables,
} from '../../graphql/camel'
import { isNotNull } from '../../utils/collectionTools'
import { logAndCaptureException } from '../../utils/errorTools'
import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { tapLeft } from '../../utils/rx/operators'
import { userClaimIds } from '../../utils/userClaims'
import { AuthStateWithAccessToken, latestAuthStateWithAccessToken$ } from '../auth/state'
import { latestApolloClient$ } from '../graphql/apollo'

const practitionerSettingsFactory = (
  client: ApolloClient<unknown>,
  token: string,
  variables: GetCustomerSettingsPractitionerQueryVariables,
) =>
  from(
    client.query({
      query: GetCustomerSettingsPractitionerDocument,
      context: {
        uri: process.env.GATSBY_CAMEL_URL,
        token,
      },
      variables,
      // override global error policy
      errorPolicy: 'none',
    }),
  ).pipe(
    // ignore `errors` in FetchResult since `errorPolicy` is `none` (Apollo will throw if errors returned from server)
    map(({ data }) => {
      return {
        _tag: 'Right' as const,
        data,
      }
    }),
    catchError(coerceCaughtToLeft),
  )

const publishProvisioningNeededFactory = (
  client: ApolloClient<unknown>,
  token: string,
  variables: PublishCustomerProvisioningNeededMutationVariables,
) =>
  from(
    client.mutate({
      mutation: PublishCustomerProvisioningNeededDocument,
      context: {
        uri: process.env.GATSBY_CAMEL_URL,
        token,
      },
      variables,
      // override global error policy
      errorPolicy: 'none',
    }),
  ).pipe(
    // ignore `errors` in FetchResult since `errorPolicy` is `none` (Apollo will throw if errors returned from server)
    map(({ data }) => {
      return {
        _tag: 'Right' as const,
        data,
      }
    }),
    catchError(coerceCaughtToLeft),
  )

export const publishProvisioningNeededEvent$ = latestAuthStateWithAccessToken$.pipe(
  filter(
    (authState): authState is Extract<AuthStateWithAccessToken, { isAuthenticated: true }> =>
      authState.isAuthenticated,
  ),
  // check once per NetSuite id
  distinctUntilKeyChanged(
    'user',
    (a, b) => a[userClaimIds.netSuiteId] === b[userClaimIds.netSuiteId],
  ),
  switchMap(({ accessToken, user }) =>
    latestApolloClient$.pipe(
      switchMap((client) =>
        practitionerSettingsFactory(client, accessToken, {
          customerId: user[userClaimIds.netSuiteId],
        }),
      ),
      switchMap((value) => (value._tag === 'Right' ? of(value.data) : EMPTY)),
      switchMap((data) => {
        const provisioningPending = data.getCustomerSettings?.practitioner?.provisioningPending
        return provisioningPending?.length && provisioningPending.every(isNotNull)
          ? of(Array.from(provisioningPending).sort())
          : EMPTY
      }),
      distinctUntilChanged((a, b) => a.join(',') === b.join(',')),
      switchMap((resources) =>
        latestApolloClient$.pipe(
          switchMap((client) =>
            publishProvisioningNeededFactory(client, accessToken, {
              input: {
                customerId: user[userClaimIds.netSuiteId],
                resources,
                meta: {
                  origin: 'Frontend',
                  timestamp: new Date().toISOString(),
                },
              },
            }).pipe(tapLeft(({ error }) => logAndCaptureException(error))),
          ),
        ),
      ),
    ),
  ),
)
