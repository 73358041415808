import { useCallback } from 'react'

import { usePageUrl } from '../../pages/hooks/usePageUrl'
import { Dispensary } from '../types'
import { replaceCurrentDispensarySlug } from '../utils'
import { useDispensaryByCodeAsync } from './useDispensaryByCodeAsync'
import { useDispensaryContext } from './useDispensaryContext'
import { useUserDispensaryClaims } from './useUserDispensaryClaims'

export interface UseUserDispensary {
  getUserDispensary: () => Promise<Dispensary | undefined>
  getRedirectionStatus: () => Promise<RedirectionStatus>
  attemptRedirect: () => Promise<boolean>
}

export type RedirectionStatus =
  | {
      shouldRedirect: true
      userDispensary: Dispensary
    }
  | {
      shouldRedirect: false
    }

export const useUserDispensary = (): UseUserDispensary => {
  const dispensary = useDispensaryContext()
  const { navigate } = usePageUrl()
  const { isValidUser, getClaims } = useUserDispensaryClaims()
  const { getDispensaryByCode } = useDispensaryByCodeAsync()

  const getUserDispensary = useCallback(async (): Promise<Dispensary | undefined> => {
    const isValid = await isValidUser()
    if (!isValid) {
      return undefined
    }
    const dispensaryClaims = await getClaims()
    if (!dispensaryClaims?.practitionerCode) {
      return undefined
    }
    const { practitionerCode } = dispensaryClaims
    return getDispensaryByCode({ practitionerCode })
  }, [isValidUser, getClaims, getDispensaryByCode])

  const getRedirectionStatus = useCallback(async (): Promise<RedirectionStatus> => {
    if (!dispensary?.isInvalid) {
      return { shouldRedirect: false }
    }
    const userDispensary = await getUserDispensary()
    if (!userDispensary) {
      return { shouldRedirect: false }
    }
    return { shouldRedirect: true, userDispensary }
  }, [dispensary?.isInvalid, getUserDispensary])

  const attemptRedirect = useCallback(async (): Promise<boolean> => {
    const redirectionStatus = await getRedirectionStatus()
    if (redirectionStatus.shouldRedirect) {
      const redirectPath = replaceCurrentDispensarySlug(redirectionStatus.userDispensary.slug)
      navigate(redirectPath, { forceBase: true })
      return true
    }
    return false
  }, [getRedirectionStatus, navigate])

  return { getUserDispensary, getRedirectionStatus, attemptRedirect }
}
