import { langCodes } from '../../utils/siteSettings'

export const dispensaryBasePathPrefix = 'u'
export const dispensaryLogoutPath = `${dispensaryBasePathPrefix}-logout`

export const dispensaryPractitionerCodes = {
  invalidDispensaryUrl: {
    env: process.env.DISPENSARY_INCORRECT_URL_PRACTITIONER_CODE,
    defaultValues: {
      [langCodes.en_CA]: 'CA_INCORRECT_DISPENSARY_URL',
      default: 'INCORRECT_DISPENSARY_URL',
    },
    wellWorldPrefix: process.env.DISPENSARY_INCORRECT_URL_WW_PREFIX || 'WELLWORLD_',
  },
} as const
