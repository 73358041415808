import { ApolloQueryResult, ObservableQuery } from '@apollo/client'
import { Observable, Subscriber } from 'rxjs'

/** Adapt ObservableQuery from from Apollo to RxJS */
export const fromObservableQuery = <T, V>(
  obsQuery: ObservableQuery<T, V>,
): Observable<ApolloQueryResult<T>> =>
  new Observable<ApolloQueryResult<T>>((subscriber: Subscriber<ApolloQueryResult<T>>) =>
    obsQuery.subscribe(subscriber),
  )
