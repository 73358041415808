import { useCallback, useState } from 'react'

import { useDispensaryContext } from './useDispensaryContext'
import { UseUserDispensaryClaims, useUserDispensaryClaims } from './useUserDispensaryClaims'

export interface UseShouldUpdateDispensary {
  shouldUpdateDispensary: () => Promise<ShouldUpdateDispensary>
  loading: boolean
  refreshClaims: UseUserDispensaryClaims['refreshClaims']
}

export interface ShouldUpdateDispensary {
  shouldUpdateUser: boolean
  shouldUpdateCart: boolean
}

export const useShouldUpdateDispensary = (): UseShouldUpdateDispensary => {
  const dispensary = useDispensaryContext()
  const { isValidUser, getClaims, refreshClaims } = useUserDispensaryClaims()

  const [loading, setLoading] = useState(false)

  const shouldUpdateDispensary = useCallback(async (): Promise<ShouldUpdateDispensary> => {
    if (!dispensary || dispensary.isInvalid) {
      return {
        shouldUpdateUser: false,
        shouldUpdateCart: false,
      }
    }
    setLoading(true)
    const isValid = await isValidUser()
    if (!isValid) {
      setLoading(false)
      return {
        shouldUpdateUser: false,
        shouldUpdateCart: false,
      }
    }
    const dispensaryClaims = await getClaims()
    if (!dispensaryClaims) {
      setLoading(false)
      return {
        shouldUpdateUser: true,
        shouldUpdateCart: false,
      }
    }
    const { practitionerCode: dispensaryPractitionerCode } = dispensary
    const { practitionerCodeRecent: claimsPractitionerCodeRecent } = dispensaryClaims

    setLoading(false)
    return {
      shouldUpdateUser:
        !claimsPractitionerCodeRecent ||
        dispensaryPractitionerCode !== claimsPractitionerCodeRecent,
      shouldUpdateCart:
        !!claimsPractitionerCodeRecent &&
        dispensaryPractitionerCode !== claimsPractitionerCodeRecent,
    }
  }, [dispensary, isValidUser, getClaims])

  return { shouldUpdateDispensary, loading, refreshClaims }
}
