import * as R from 'ramda'
import { useCallback } from 'react'

import { GetEncodedDispensaryPromosQuery } from '../../../../graphql/camel'
import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { usePromisify } from '../../../../hooks/usePromisify'
import type { ResolveType } from '../../../../utils/types'
import {
  translateCodeForDispensary,
  translateCodeForGlobal,
} from '../../../cart/hooks/usePromoCodeUtilities/translateCode'
import { EScriptParamsLoader } from '../../../escript/types'
import { setDispensaryCartState } from '../../events/cart'
import { useDispensaryPromos } from '../useDispensaryPromos'
import {
  UseCartSetDispensaryPromoCode,
  useCartSetDispensaryPromoCode,
} from '../../../cart/hooks/useCartSetDispensaryPromoCode'
import { TranslateCodeResult } from '../../../cart/hooks/usePromoCodeUtilities'
import {
  appendCartDispensaryPromoCode,
  writeCartDispensaryPromoCodes,
} from '../../../cart/dispensary-promo'

export type UseSetDispensaryPromoCodeOnCart = [
  setDispensaryPromoCodeOnCart: () => Promise<
    ResolveType<ReturnType<UseCartSetDispensaryPromoCode['0']>> | undefined
  >,
  result: UseCartSetDispensaryPromoCode['1'],
]

export interface UseSetDispensaryPromoCodeToCartOptions {
  eScriptParamsLoader: EScriptParamsLoader
}

const translateDispensaryPromoCode =
  (loadPromosPromise: Promise<GetEncodedDispensaryPromosQuery | undefined>) =>
  async (promoCode: string): Promise<TranslateCodeResult> => {
    const dispensaryPromos = (await loadPromosPromise)?.getEncodedDispensaryPromos
    return await (
      !dispensaryPromos
        ? translateCodeForGlobal
        : translateCodeForDispensary(dispensaryPromos.promos)
    )(promoCode)
  }

export const useSetDispensaryPromoCodeOnCart = ({
  eScriptParamsLoader,
}: UseSetDispensaryPromoCodeToCartOptions): UseSetDispensaryPromoCodeOnCart => {
  const { loadParams } = eScriptParamsLoader
  const [setDispensaryCodeOnCart, result] = useCartSetDispensaryPromoCode()
  const { error } = result

  useLogAndCaptureError(error)

  const { load: loadDispensaryPromos } = usePromisify(useDispensaryPromos())

  const setDispensaryPromoCodeOnCart = useCallback(async () => {
    const { promoCode: promoCodeRaw } = await loadParams()
    const promoCode = promoCodeRaw?.trim()
    if (!promoCode) {
      return
    }
    const translateCodeResult =
      await translateDispensaryPromoCode(loadDispensaryPromos())(promoCode)

    if (translateCodeResult._tag === 'not_translated') {
      return
    }

    const dispensaryPromoCode = writeCartDispensaryPromoCodes(
      appendCartDispensaryPromoCode(
        {
          dispensaryCode: translateCodeResult.inputCode,
          rootCode: translateCodeResult.translatedCode,
        },
        [],
      ),
    )
    setDispensaryCartState({ type: 'cart_set_dispensary_promo_code_start', dispensaryPromoCode })
    return setDispensaryCodeOnCart({
      variables: {
        dispensaryPromoCode,
      },
    }).then(
      R.tap(() =>
        setDispensaryCartState({
          type: 'cart_set_dispensary_promo_code_complete',
          dispensaryPromoCode,
        }),
      ),
    )
  }, [setDispensaryCodeOnCart, loadParams, loadDispensaryPromos])

  return [setDispensaryPromoCodeOnCart, result]
}
