import { bind } from '@react-rxjs/core'
import { map } from 'rxjs'

import { decodeJwt } from '../../utils/tokenTools'
import { latestAuthStateWithAccessToken$ } from './state'

export interface UserAccessPolicy {
  canPurchaseTestKits: boolean
  canReadDrugNutrient: boolean
  canReadPharmacyContent: boolean
  canReadProfessionalLibrary: boolean
}

const defaultPolicy: UserAccessPolicy = {
  canPurchaseTestKits: false,
  canReadDrugNutrient: false,
  canReadPharmacyContent: false,
  canReadProfessionalLibrary: false,
}

const parseUserAccessPolicyScope = (scope: string | undefined): UserAccessPolicy => {
  const apiScopes = scope?.split(' ') ?? []
  return {
    canPurchaseTestKits: apiScopes.includes('purchase:test_kit'),
    canReadDrugNutrient: apiScopes.includes('read:drug_nutrient'),
    canReadPharmacyContent: apiScopes.includes('read:pharmacy_content'),
    canReadProfessionalLibrary: apiScopes.includes('read:professional_library'),
  }
}

export const userAccessPolicy$ = latestAuthStateWithAccessToken$.pipe(
  map((authState) =>
    authState.isAuthenticated
      ? parseUserAccessPolicyScope(
          decodeJwt(authState.accessToken)?.payload?.scope as string | undefined,
        )
      : defaultPolicy,
  ),
)

export const [useUserAccessPolicy, latestUserAccessPolicy$] = bind(userAccessPolicy$, defaultPolicy)
