import { ApolloError, useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

import { CartFragment, GetGuestCartDocument } from '../../../graphql/magento'

export type UseGuestCartGetById = [
  getGuestCart: (cartId: string) => void,
  state: {
    cart?: CartFragment
    loading: boolean
    error?: ApolloError | Error
    called: boolean
  },
]

export const useGuestCartGetById = (): UseGuestCartGetById => {
  const [cartId, setCartId] = useState<string | undefined>(undefined)

  const [doGetGuestCart, { data, loading, error, called }] = useLazyQuery(GetGuestCartDocument, {
    // return partial data if available
    errorPolicy: 'all',
  })

  const getGuestCart = useCallback((guestCartId: string) => {
    setCartId(guestCartId)
  }, [])

  const cart = data?.cart || undefined

  useEffect(() => {
    if (loading || called) {
      return
    }
    if (!cartId) {
      return
    }
    doGetGuestCart({
      variables: { cartId },
    })
  }, [called, cartId, doGetGuestCart, loading])

  return [getGuestCart, { cart, loading, error, called }]
}
