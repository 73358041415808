import React, { useCallback, useEffect } from 'react'

import { HubSpotContext } from '../layouts/context'
import { useAuth } from '../lib/auth'

const _hsq = typeof window !== 'undefined' && Array.isArray(window._hsq) ? window._hsq : undefined

export interface HubSpotContextProviderProps {
  children: React.ReactNode
}

const HubSpotContextProvider: React.FC<HubSpotContextProviderProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth()

  const trackEvent = useCallback((event: { id: string; value: number }) => {
    if (!_hsq || !event?.id) {
      return
    }
    _hsq.push(['trackEvent', event])
  }, [])

  useEffect(() => {
    const identify = () => {
      if (!_hsq || !isAuthenticated || !user) {
        return
      }
      _hsq.push([
        'identify',
        {
          email: user.email,
        },
      ])
    }

    // follow behavior of gatsby-plugin-hubspot
    if (typeof window.requestAnimationFrame === 'function') {
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(identify)
      })
    } else {
      // simulate 2 requestAnimationFrame calls
      setTimeout(identify, 32)
    }
  }, [isAuthenticated, user])

  return <HubSpotContext.Provider value={{ trackEvent }}>{children}</HubSpotContext.Provider>
}

export default HubSpotContextProvider
