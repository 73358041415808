import { Button, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { SnackbarAction, SnackbarKey } from 'notistack'
import React, { FC, MouseEvent, useCallback, useContext } from 'react'
import styled from 'styled-components'

import { publishViewCartEvent } from '../../lib/analytics/cart'
import { useCartContext } from '../../lib/cart'
import { ToggleCartContext } from '../../lib/cart/context'

export interface CartToastActionsProps {
  snackbarKey: SnackbarKey
  dismissToast: (snackbarKey: SnackbarKey) => void
}

const ShowCartButton = styled(Button)``

const CloseButton = styled(IconButton)`
  margin-left: 10px;

  &.MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`
export const getCartToastActions = (
  props: Omit<CartToastActionsProps, 'snackbarKey'>,
): SnackbarAction => {
  const Actions = (snackbarKey: SnackbarKey) => {
    return <CartToastActions snackbarKey={snackbarKey} {...props} />
  }
  return Actions
}

export const CartToastActions: FC<CartToastActionsProps> = ({ snackbarKey, dismissToast }) => {
  const { isCartOpen, toggleCart } = useContext(ToggleCartContext)
  const { cart } = useCartContext()

  const closeToast = useCallback(() => dismissToast(snackbarKey), [dismissToast, snackbarKey])

  const showCart = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (!isCartOpen) {
        toggleCart()
        if (cart) {
          publishViewCartEvent(cart)
        }
      }
      closeToast()
    },
    [cart, closeToast, isCartOpen, toggleCart],
  )

  return (
    <>
      {!isCartOpen && (
        <ShowCartButton color="inherit" size="small" onClick={showCart}>
          Show Cart
        </ShowCartButton>
      )}
      <CloseButton size="small" aria-label="close" color="inherit" onClick={closeToast}>
        <CloseIcon fontSize="small" />
      </CloseButton>
    </>
  )
}
